import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { PRIMARY } from "../../styles/colors";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const CheckboxButton = withStyles(() => ({
  root: {
    color: PRIMARY,
    padding: 6,
    fontSize: 30,
    "&:hover": {
      backgroundColor: emphasize(PRIMARY, 0.92),
      boxShadow: "none",
    },
    "&$checked": {
      color: PRIMARY,
      "&:hover": {
        backgroundColor: emphasize(PRIMARY, 0.92),
        boxShadow: "none",
      },
    },
  },
  checked: {},
}))(Checkbox);

export default CheckboxButton;
