import React, { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./Header.css";
import Logo from "../../img/abbott-logo.png";

const Header = (props) => {
  let path = useLocation().pathname;
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="dark"
      fixed="top"
      className="text-white"
    >
      <div className="nav-container navbar-top" />
      <div className="nav-container navbar-bottom">
        <Navbar.Brand
          className="my-auto"
          href="/"
          rel="noopener noreferrer"
          aria-label="Link zu Startseites"
        >
          <img
            alt="Abbott Logo"
            src={Logo}
            width="103"
            height="26"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey={path}>
            <Nav.Link href="/" eventKey="/start">
              Startseite
            </Nav.Link>
            <NavDropdown
              title="Veranstaltungen"
              id="nav-veranstaltungen"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              active={path.startsWith("/events") || path.startsWith("/veranstaltung") ? true : false}
            >
              {props.categories.map((category) => {
                const bg = {
                  minWidth: "10px",
                  position: "absolute",
                  height: "100%",
                  left: 0,
                  top: 0,
                  backgroundColor: category.color,
                  color: category.color,
                };
                return (
                  <NavDropdown.Item
                    key={category.id}
                    href={"/events/" + category.id}
                  >
                    <div style={bg}>.</div>
                    {category.category_name}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
            <Nav.Link href="/kontakt" eventKey="/kontakt">
              Kontakt
            </Nav.Link>
            <Nav.Link href="/ueber-uns" eventKey="/ueber-uns">
              Über uns
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
