// Externe Importe
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Interne Importe
/* import {  } from "../../styles/colors";*/
import { HeaderImage } from "../../components"; 

// Styles
const useStyles = makeStyles(() => ({
    content: {
        padding: "52px 0 150px 0",
    },
}));

const About = () => {
    // Variables
    const classes = useStyles();

    return (
        <div className="site-wrapper">
            <HeaderImage withCategory={false}/>

            <div className={classes.content}>
                <div className="content-wrapper">
                    <h1>Über uns</h1>
                </div>
            </div>
        </div>
    );
};

export default About;
