import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { doctorForm } from "./paymentDataArrays";
import {
  SecondaryButton,
  ContactDialog,
  ContactDialogChange,
} from "../../../components";
import { DataTable } from "./DataTable";

const useStyles = makeStyles(() => ({
  container: {
    textTransform: "uppercase",
    fontFamily: "brandon-grotesque, sans-serif",
  },
}));

const Form = ({ doctor, eventId }) => {
  const classes = useStyles();
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isContactChangeOpen, setIsContactChangeOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleContactClose = () => {
    setIsContactOpen(false);
  };
  const handleContactChangeClose = () => {
    setIsContactChangeOpen(false);
  };
  const handleContactChangeCloseOnConfirm = () => {
    setIsContactChangeOpen(false);
    setIsSnackbarOpen(true)
  };

  return (
    <div className={classes.container}>
      <h4>Sind Ihre Daten noch korrekt?</h4>
      <DataTable form={doctorForm} data={doctor} />
      <p style={{ marginTop: "40px" }}>
        Falls Ihre Daten nicht mehr korrekt sind, können sie uns das gerne
        mitteilen und wir korrigieren sie für Sie.
      </p>
      {/* TODO: Kontakt Dialog */}
      <SecondaryButton onClick={() => setIsContactChangeOpen(true)}>
        Korrektur anforderen
      </SecondaryButton>
      <p style={{ marginTop: "40px" }}>
        Das sind nicht Sie, Sie haben aber dennoch Interesse an dieser
        Veranstaltung? Dann kontaktieren Sie uns doch einfach.
      </p>
      {/* TODO: Kontakt Dialog */}
      <SecondaryButton onClick={() => setIsContactOpen(true)}>
        Ich bin neu
      </SecondaryButton>

      <ContactDialog
        isOpen={isContactOpen}
        handleClose={handleContactClose}
        eventId={eventId}
      />
      <ContactDialogChange
        isOpen={isContactChangeOpen}
        handleClose={handleContactChangeClose}
        handleCloseOnConfirm={handleContactChangeCloseOnConfirm}
        eventId={eventId}
        oldDoctorData={doctor}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        autoHideDuration={6000}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setIsSnackbarOpen(false)} severity="success">
          Vielen Dank! Wir werden Ihre Daten in Kürze ändern.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Form;
