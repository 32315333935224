import { withStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TITLEFONT } from "../../styles/colors";

const CorporateButton = withStyles(() => ({
        root: {
            backgroundColor: "#024f74",
            color:"#fff",
            borderRadius: 0,
            margin: 0,
            boxShadow: "none",
            height: "32px",
            textTransform: "uppercase",
            padding: "0px 20px",
            fontSize: "16px",
            fontFamily: TITLEFONT,
            fontWeight: "600",
            "& .MuiSvgIcon-root": {
                fontSize: "18px"
            },
            "&:hover": {
                backgroundColor: "#024f74",
                boxShadow: "none",
                color:'#fff'
            },
            "&::before": {
                content: "''",
                display: "block",
                position: "absolute",
                backgroundColor: "transparent",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                border: "8px solid #024f74",
                transform: "scale(1)",
                transition: "transform .25s",
            },
            "&:hover:before": {
                transform: "scale(1.05)",
            },
        }
    }
))(Button);

export default CorporateButton;