export const doctorData = {
  salutation: "",
  academic_degree: "",
  first_name: "",
  last_name: "",
  email: "",
  emailConfirmation: "",
  company: "",
  message: "",
  event_id: "",
};

export const doctorForm = [
  {
    id: "salutation",
    label: "Anrede",
    type: "select",
    value: "",
    options: [
      { value: "mrs", text: "Frau" },
      { value: "mr", text: "Herr" },
    ],
    required: false,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "academic_degree",
    label: "Titel",
    type: "text",
    value: "",
    max: 31,
    required: true,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "first_name",
    label: "Vorname",
    type: "text",
    value: "",
    required: true,
    max: 255,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "last_name",
    label: "Nachname",
    type: "text",
    value: "",
    required: true,
    max: 255,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    value: "",
    format: "email",
    required: false,
    max: 255,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "emailConfirmation",
    label: "Email",
    type: "email",
    value: "",
    format: "email",
    required: true,
    max: 255,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "company",
    label: "Klinik / Praxis",
    type: "text",
    value: "",
    required: true,
    colLg: 6,
    colMd: 6,
    colSm: 6,
  },
  {
    id: "message",
    label: "Nachricht",
    type: "textarea",
    value: "",
    required: false,
    colLg: 12,
    colMd: 12,
    colSm: 12,
  },
];
