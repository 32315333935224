import { withStyles, } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

const StyledTableRow = withStyles(() => ({
        root: {
            backgroundColor: "#f2f2f2",
            color: "#1a1a1a",
            borderBottom: "2px solid #fff"
        }
    }
))(TableRow);

export default StyledTableRow;