// Externe Importe
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

// Interne Importe
import http from "../../utils/http-common";
import {
  StyledStepper,
  EmphasizedButton,
  SecondaryButton,
  EventBoxDetail,
} from "../../components";
import Payment from "./steps/Payment";
import Finish from "./steps/Finish";
import Form from "./steps/Form";
import Confirm from "./steps/Confirm";

// Styles
const useStyles = makeStyles(() => ({
  stepperButtonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "32px",
  },
}));

const EnrollWithPayment = ({ congress, doctor }) => {
  // Variables
  const classes = useStyles();
  let params = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const [startValidation, setStartValidation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentData, setPaymentData] = useState({});

  function getSteps() {
    return ["Ihre Daten", "Zahlungsart", "Bestätigung"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Form doctor={doctor} eventId={congress.id}/>;
      case 1:
        return (
          <Payment
            handleNext={handleNext}
            startValidation={startValidation}
            callBack={callBack}
            badCallback={badCallback}
            paymentData={paymentData}
            paymentMethod={paymentMethod}
          />
        );
      case 2:
        return (
          <Confirm
            doctor={doctor}
            paymentData={paymentData}
            paymentMethod={paymentMethod}
          />
        );
      default:
        return "Fehler, Step nicht definiert";
    }
  }

  // Stepper
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStartValidation(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validatePaymentData = () => {
    setStartValidation(true);
  };

  const callBack = (method, paymentData) => {
    setStartValidation(false);
    /* console.log(method)
    console.log(paymentData) */
    setPaymentMethod(method);
    setPaymentData(paymentData);
  };

  const badCallback = () => {
    setStartValidation(false);
  };

  const handleConfirm = async () => {
    await http
      .post(`/payment/`, {
        params: {
          event_id: params.event_id,
          doctor_id: params.doctor_id,
          hash: params.hash,
          paymentMethod: paymentMethod,
          paymentData: paymentData,
        },
      })
      .then(function (response) {
        if (response.data.result === null) {
          history.push("/fehler401");
        } else {
          handleNext();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <StyledStepper activeStep={activeStep} steps={steps} />
      {activeStep < steps.length ? (
        <div>
          <Grid container spacing={10}>
            <Grid item lg={9} sm={12}>
              {getStepContent(activeStep, params, handleNext, handleBack)}
            </Grid>
            <EventBoxDetail event={congress} />
          </Grid>

          <div className={classes.stepperButtonSection}>
            {activeStep === 0 || (
              <SecondaryButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Zurück
              </SecondaryButton>
            )}

            {activeStep === 0 && (
              <EmphasizedButton
                onClick={handleNext}
                className={classes.buttonMargin}
              >
                Weiter
              </EmphasizedButton>
            )}
            {activeStep === 1 && (
              <EmphasizedButton
                onClick={validatePaymentData}
                className={classes.buttonMargin}
              >
                Weiter
              </EmphasizedButton>
            )}
            {activeStep === steps.length - 1 && (
              <EmphasizedButton
                onClick={handleConfirm}
                className={classes.buttonMargin}
              >
                Jetzt zahlungspflichtig anmelden
              </EmphasizedButton>
            )}
          </div>
        </div>
      ) : (
        <Finish />
      )}
    </div>
  );
};

export default EnrollWithPayment;
