import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { PRIMARY, TITLEFONT } from "../../styles/colors";
import clsx from "clsx";
import { FormatDate } from "../index";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: "#f2f2f2",
    transition: "background-color .25s",
    /* height: "100%", */
  },
  content: {
    padding: "15px",
    fontFamily: TITLEFONT,
    textTransform: "uppercase",
    fontSize: ".8rem",
    letterSpacing: ".6px",
  },
  table: {
    marginBottom: "95px",
    "& td:first-child": {
      paddingRight: "25px",
      fontWeight: 700,
    },
  },
  tableSponsoring: {
    /* marginBottom: "30px", */
    "& td:first-child": {
      paddingRight: "25px",
      fontWeight: 700,
    },
  },
  boxFooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: "50px",
    color: "#0096D6",
    "& div": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  icon: {
    fontSize: "30px",
    padding: 0,
    color: PRIMARY,
  },
}));

const EventBoxDetail = (props) => {
  const classes = useStyles();
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    setColor(props.event.category_color);
  }, [props]);

  const title = {
    padding: "15px",
    backgroundColor: color,
    color: "#fff",
    fontWeight: 700,
    marginBottom: 0,
    wordWrap: "break-word",
  };

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
        <h4>gewähltes Event</h4>
      <Paper
        className={
          classes.paper
        } /* onClick={()=> {props.event.category_name === "Sponsoring" && navigateToDetail(props.event.id) }} */
      >
        <h3 style={props.event.category_color && title}>
          {props.event.event_name}
        </h3>
        <div className={classes.content}>
          <table
            className={
              props.event.category_name === "Sponsoring"
                ? classes.tableSponsoring
                : classes.table
            }
          >
            <tbody>
              <tr>
                <td>Datum:</td>
                <td>
                  {props.event.date_from && (
                    <FormatDate date={props.event.date_from} />
                  )}
                </td>
              </tr>
              <tr>
                <td>Ort:</td>
                <td>
                  {props.event.addresses && props.event.addresses[0]
                    ? props.event.addresses[0].city
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Kategorie:</td>
                <td>{props.event.topic_name}</td>
              </tr>
              <tr>
                <td>VA:</td>
                <td>
                  {props.event.organizer &&
                    props.event.organizer.organizer_name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {props.event.category_name !== "Sponsoring" && (
          <div className={clsx(classes.boxFooter, classes.content)}>
            <div>
              {props.event.costs
                ? "€ " +
                  (
                    Math.round(parseFloat(props.event.costs) * 100) / 100
                  ).toFixed(2) +
                  "*"
                : ""}
            </div>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default EventBoxDetail;
