import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { HeaderImage } from "../../components";

const useStyles = makeStyles(() => ({
  content: {
    padding: "52px 0 150px 0",
    wordWrap: "break-word",
  },
  headline: {
    marginTop: "50px",
  },
}));

const Privacy = () => {
  const classes = useStyles();

  return (
    <div className="site-wrapper">
      <HeaderImage withCategory={false} />
      <div className={classes.content}>
        <div className="content-wrapper">
          <h1>Datenschutzbestimmungen</h1>
          <p>Stand: Mai 2018</p>

          {/* Allgemeines */}
          <div>
            <p>
              <strong>
                Die DSGVO gibt Ihnen neben den bereits hervorgehobenen neue und
                erweiterte Rechte für den Umgang mit Ihren personenbezogenen
                Daten.
              </strong>
            </p>
            <p>Diese umfassen im Einzelnen:</p>
            <ul>
              <li>
                das Recht, sämtliche falschen oder unvollständigen,
                personenbezogenen Daten richtig zu stellen oder zu löschen, die
                Abbott von Ihnen besitzt;
              </li>
              <li>
                das Recht, Ihre in Abbotts Besitz befindlichen,
                personenbezogenen Daten einzusehen;
              </li>
              <li>
                in einigen Fällen das Recht, der Verarbeitung Ihrer
                personenbezogenen Daten aus legitimen Gründen zu widersprechen;
              </li>
              <li>
                das Recht, nicht zwangsläufig automatischen
                Entscheidungsprozessen wie Verhaltensanalysen unterworfen zu
                sein, die bestimmte persönliche Aspekte bewerten. Im Abschnitt
                „Cookies“ der Datenschutzrichtlinien finden Sie weitere
                Informationen zur Ausübung dieses Rechts, sofern auf die
                Verwendung von Cookies hingewiesen wird.
              </li>
              <li>
                {" "}
                Wenn Sie uns Ihre personenbezogenen Daten freiwillig oder im
                Rahmen eines Vertrages überlassen haben, dann müssen wir diese
                Daten auch wieder an Sie übertragen oder, sofern für uns
                technisch durchführbar, an ein anderes Unternehmen;
              </li>
              <li>
                Sie haben auch das Recht, bei Ihrer nationalen
                Datenschutzbehörde eine Beschwerde einzulegen oder eine
                Entschädigung für etwaige erlittene Schäden zu bekommen; sowie
              </li>
              <li>
                das Recht, Abbott ohne Nachteile Ihre Zustimmung zur
                Verarbeitung Ihrer personenbezogenen Daten zu entziehen, sofern
                Sie diese Abbott Zustimmung gegeben hatten.
              </li>
            </ul>
          </div>

          {/* Weitere wissenswerte Informationen */}
          <div>
            <h3 className={classes.headline}>
              WEITERE WISSENSWERTE INFORMATIONEN
            </h3>
            <p>
              <strong>Internationale Datenübertragung</strong> - Wir müssen Ihre
              personenbezogenen Daten möglicherweise an Drittländer außerhalb
              des Europäischen Wirtschaftsraumes („<strong>EEA</strong>“)
              weiterleiten, die nicht denselben Sicherheitsstandards unterliegen
              wie in der EU. Zur Sicherheit Ihrer personenbezogenen Daten werden
              wir solche Datenübertragungen nur auf der Grundlage einer (i)
              Entscheidung der Europäischen Kommission vornehmen, die ein
              solches Verfahren erlaubt, oder (ii) im Rahmen von
              Standardvertragsklauseln, die von der EU genehmigt sind. Wir
              möchten Sie darauf hinweisen, dass Abbott entsprechende
              Vereinbarungen zur Übertragung Ihrer personenbezogenen Daten von
              EWG-Mitgliedern in Drittländer, einschließlich der USA,
              abgeschlossen hat. Bitte wenden Sie sich an unsere EU-Abteilung
              (weitere Details unten stehend), wenn Sie eine Kopie der oder
              Zugriff auf die EU Standardvertragsklauseln wünschen, aufgrund
              derer wir Ihre personenbezogenen Daten von einem EWG-Mitgliedsland
              exportieren.
            </p>
            <p>
              <strong>Speicherung</strong> - Ihre personenbezogenen Daten werden
              nur solange gespeichert, wie dies erforderlich ist, um Ihnen die
              Dienstleistung oder das Produkt zu liefern, welche Sie bei Abbott
              bestellt haben; bzw. um auf unsere Internetseite, sowie unsere
              Dienstleistungen zuzugreifen. Alternativ speichern wir Ihre
              personenbezogenen Daten solange auf, wie dies für eine Bearbeitung
              Ihrer Anfragen oder Anliegen oder im Rahmen gesetzlicher Vorgaben
              erforderlich ist; dies umfasst auch Vertragsdaten oder Daten zu
              Produkten oder zur Sicherheit medizinischer Geräte. Wenden Sie
              sich bitte für weitere Informationen an unseren örtlichen
              Kundendienst oder an unsere EU-Abteilung unter unten stehenden
              Kontaktdaten.
            </p>
            <p>
              <strong>Warum benötigen wir Ihre Daten</strong> - Zur Erfüllung
              der Bedingungen des Vertrages, den Sie mit Abbott geschlossen
              haben bzw. zur Erbringung einer Dienstleistung, ist es
              erforderlich, dass Sie uns Ihre personenbezogenen Daten zur
              Verfügung stellen, damit wir ein von Ihnen bestelltes Produkt oder
              eine Dienstleistung liefern können. Sofern Sie diese
              personenbezogenen Daten nicht angeben, kann Abbott Ihnen das
              bestellte Produkt oder die Dienstleistung nicht liefern.
            </p>
            <p>
              <strong>Anliegen und Fragen:</strong> Verwenden Sie hierfür bitte
              folgende Emailadresse:{" "}
              <a href="mailto:veranstaltungskalender@abbott.com">
                veranstaltungskalender@abbott.com
              </a>
              .
            </p>
          </div>

          {/* te */}
          <div>
            <h3 className={classes.headline}>IHR EINVERSTÄNDNIS</h3>
            <p>
              Indem Sie diese Website benutzen, erklären Sie Ihr Einverständnis
              mit den Bedingungen dieser Datenschutzerklärung . Immer wenn Sie
              über diese Website Informationen an uns übermitteln, stimmen Sie
              damit der Erhebung, Verwendung und Weitergabe von Informationen
              gemäß dieser Datenschutzerklärung zu.
            </p>
          </div>

          {/* Personenbezogene Daten */}
          <div>
            <h3 className={classes.headline}>PERSONENBEZOGENE DATEN</h3>
            <p>
              <strong>Erhebung und Verwertung</strong>
            </p>
            <p>
              Personenbezogene Daten, d. h. Daten, aus denen Rückschluss auf
              Ihre Person gezogen werden können, wie Ihr Name und Ihre
              E‐Mail‐Adresse, werden nur dann erhoben, wenn Sie uns diese aktiv
              von sich aus zur Verfügung stellen, indem Sie beispielsweise mit
              uns über E‐Mail kommunizieren, Feedback geben oder Formulare
              ausfüllen und an uns versenden. Sie werden an jeder Stelle, an der
              diese Website personenbezogene Daten von Ihnen erhebt, informiert,
              welche Informationen wir zwingend benötigen, um unsere Leistungen
              anzubieten, und welche Informationen Sie freiwillig ergänzen
              können. Alle an uns übermittelten personenbezogenen Daten werden
              vertraulich behandelt und nicht an Dritte weitergeleitet, es sei
              denn, dass hierzu Ihr Einverständnis oder eine behördliche
              Anordnung vorliegt. Dritte in diesem Sinne sind nicht mit der
              Datenverarbeitung beauftragte Dienstleister.
            </p>
            <p>
              Zur Abwicklung von Geschäftstätigkeiten mit Kunden, Lieferanten,
              Geschäftspartnern und deren Mitarbeitern erfasst und verarbeitet
              Abbott deren personenbezogene Daten, wie z. B.:
            </p>
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Anfrage</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
              <li>Inhalt der Anforderung (konkrete Seite)</li>
              <li>Zugriffsstatus/HTTP-Statuscode</li>
              <li>jeweils übertragene Datenmenge</li>
              <li>Website, von der die Anforderung kommt</li>
              <li>Browser</li>
              <li>Betriebssystem und dessen Oberfläche</li>
              <li>Sprache und Version der Browsersoftware,</li>
            </ul>
            <p>
              die erforderlich sind, um die Geschäftstätigkeit auszuüben und
              geltende rechtliche und regulatorische Anforderungen einzuhalten.
              Abbott behält sich vor, diese Daten an Dienstleister,
              Tochtergesellschaften und verbundene Unternehmen weiterzugeben.
            </p>
            <p>
              Diese persönlichen Daten können für Zahlungs‐ und damit verbundene
              Buchhaltungszwecke über unser Buchhaltungssystem an die USA
              übermittelt werden, wo die Datenschutzgesetze ggf. nicht dasselbe
              Schutzniveau bieten wie die Gesetze in Ihrem Land, wenngleich
              Abbott geeignete Maßnahmen zum Schutz der betreffenden Daten
              ergreift.
            </p>
            <p>
              Mit Nutzung dieser Website erklären Sie sich damit einverstanden,
              dass personenbezogene Daten von Ihnen durch Abbott erhoben werden
              können und in den USA und anderen Ländern außerhalb der
              Europäischen Union in denen Abbott tätig ist verarbeitet und
              gespeichert werden können. Abbott hat angemessene Maßnahmen
              getroffen, um den Schutz Ihrer personenbezogenen Daten auch in
              diesen Ländern sicherzustellen, unter anderem durch internationale
              Verträge auf Grundlage der sog. Standardverträge seitens der
              Europäischen Union.
            </p>
            <p>
              Wenn Kunden, Lieferanten, Geschäftspartner und deren Mitarbeiter
              die Erfassung und Verarbeitung dieser persönlichen Daten nicht
              akzeptieren, ist eine Geschäftsbeziehung mit Abbott nicht möglich
            </p>
            <p>
              Um persönliche Daten einzusehen, zu korrigieren oder löschen zu
              lassen, kontaktieren Sie bitte folgende Adresse{" "}
              <a href="mailto:datenschutz.de@abbott.com">
                datenschutz.de@abbott.com
              </a>
            </p>
            <p>
              <strong>Verwendungszwecke / Aufbewahrung</strong>
            </p>
            <p>
              Die von Ihnen erfassten persönlichen Daten werden wir nur dazu
              verwenden, Ihnen die gewünschten Informationen oder
              Dienstleistungen zur Verfügung zu stellen, oder aber zu anderen
              Zwecken, zu denen Sie Ihre Einwilligung erteilt haben. Ihre
              personenbezogenen Daten werden nur so lange gespeichert, bis der
              jeweilige Zweck, zu dem sie uns anvertraut wurden, erfüllt ist,
              sofern keine anderslautenden gesetzlichen Verpflichtungen
              bestehen.
            </p>
            <p>
              <strong>Recht auf Auskunft und Widerruf</strong>
            </p>
            <p>
              Sie sind berechtigt, Auskunft zu verlangen, welche auf Ihre Person
              bezogenen Daten bei uns gespeichert sind, und diese zu überprüfen
              und ggf. zu korrigieren. Zudem sind Sie berechtigt, die
              Einwilligung in die Verwendung Ihrer personenbezogenen Daten
              jederzeit mit Wirkung für die Zukunft zu widerrufen. Hierzu genügt
              eine E-Mail an die im Impressum genannte E-MailAdresse
            </p>
          </div>

          {/* ERHEBUNG UND VERWERTUNG VON ANDEREN DATEN */}
          <div>
            <h3 className={classes.headline}>
              ERHEBUNG UND VERWERTUNG VON ANDEREN DATEN
            </h3>
            <p>
              Durch den Aufruf unserer Website werden automatisiert passiv Daten
              übermittelt, wie beispielsweise der Name Ihres
              Internetserviceproviders, der verwendete Webbrowser, die besuchten
              Websites, die Verweildauer und Ihre IP-Adresse. Diese Daten können
              anonym für statistische und administrative Zwecke verwertet
              werden, und um die Nutzung dieser Website zu verbessern. Eine
              Zuordnung zu Ihrer Person findet nicht statt, da diese Daten nicht
              personenbezogen verwertet werden. Sie können sich daher auf
              unserer Website anonym im Sinne der gesetzlichen Vorschriften
              bewegen.
            </p>
            <p>
              Sollten Sie als Reaktion auf publizierte Dokumente auf dieser
              Webseite Informationen zurücksenden, beispielsweise durch Feedback
              in Form von Fragen, Stellungnahmen, Vorschlägen oder Ähnlichem,
              dann darf Abbott Ideen, Konzepte, Know-how oder Techniken, die in
              diesen Informationen enthalten sind, für eigene Zwecke verwenden,
              sofern es sich bei den Informationen nicht um personenbezogene
              Daten handelt.
            </p>
          </div>

          {/* Kinder */}
          <div>
            <h3 className={classes.headline}>Kinder</h3>
            <p>
              Diese Website ist nicht für Kinder unter 18 Jahren bestimmt. Daher
              erheben, verarbeiten und nutzen wir keine Informationen von
              Personen, von denen uns bekannt ist, dass sie unter 18 Jahre alt
              sind, ohne dass eine nachprüfbare Zustimmung eines gesetzlichen
              Vertreters vorliegt. Auf Antrag können die gesetzlichen Vertreter
              die von ihrem Kind zur Verfügung gestellten Informationen einsehen
              und verlangen, dass sie gelöscht werden.
            </p>
          </div>

          {/* Links */}
          <div>
            <h3 className={classes.headline}>LINKS ZU ANDEREN WEBSITES</h3>
            <p>
              Diese Website kann Links oder Referenzen zu anderen Websites
              enthalten, über die wir keine Kontrolle haben. Bitte beachten Sie,
              dass Sie beim Verlassen dieser Website auch den Geltungsbereich
              dieser Datenschutzerklärung verlassen.
            </p>
          </div>

          {/* Sicherheit */}
          <div>
            <h3 className={classes.headline}>SICHERHEIT</h3>
            <p>
              Wir setzen technische und organisatorische Sicherheitsmaßnahmen
              ein, um die von Ihnen zur Verfügung gestellten Daten vor Verlust,
              Zerstörung, Missbrauch und nicht berechtigtem Zugriff, nicht
              berechtigter Weitergabe oder nicht berechtigten Änderungen zu
              schützen. Einen 100%igen Schutz gibt es aber leider nicht,
              insbesondere nicht bei Internetübertragung. Bitte seien Sie sich
              dessen bewusst, wenn Sie entscheiden, welche Informationen Sie uns
              per E-Mail senden.
            </p>
          </div>

          {/* Cookies */}
          <div>
            <h3 className={classes.headline}>COOKIES</h3>
            <p>
              Bei Cookies handelt es sich um kleine Textdateien, die lokal im
              Zwischenspeicher Ihres InternetBrowsers gespeichert werden.
              Cookies vereinfachen Ihnen die Nutzung der von Ihnen bereits
              besuchten Websites. Die meisten Browser sind so eingestellt, dass
              sie Cookies automatisch zulassen. Sie können jedoch jederzeit die
              Einstellungen Ihres Browsers ändern, sodass das Speichern von
              Cookies deaktiviert wird oder der Browser Sie über die Platzierung
              von Cookies informiert.
            </p>
            <p>
              <strong>Einsatz von Google Analytics</strong>
            </p>
            <p>
              (1) Diese Website benutzt Google Analytics, einen Webanalysedienst
              der Google Inc. („Google“). Google Analytics verwendet sog.
              „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
              und die eine Analyse der Benutzung der Website durch Sie
              ermöglichen. Die durch den Cookie erzeugten Informationen über
              Ihre Benutzung dieser Website werden in der Regel an einen Server
              von Google in den USA übertragen und dort gespeichert. Im Falle
              der Aktivierung der IP-Anonymisierung auf dieser Website, wird
              Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
              der Europäischen Union oder in anderen Vertragsstaaten des
              Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
              in Ausnahmefällen wird die volle IPAdresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Website-Aktivitäten zusammenzustellen und um weitere mit der
              Website-Nutzung und der Internetnutzung verbundene
              Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
            </p>
            <p>
              (2) Die im Rahmen von Google Analytics von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt.
            </p>
            <p>
              (3) Sie können die Speicherung der Cookies durch eine
              entsprechende Einstellung Ihrer BrowserSoftware verhindern; wir
              weisen Sie jedoch darauf hin, dass Sie in diesem Fall
              gegebenenfalls nicht sämtliche Funktionen dieser Website
              vollumfänglich werden nutzen können. Sie können darüber hinaus die
              Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
              Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
              die Verarbeitung dieser Daten durch Google verhindern, indem sie
              das unter dem folgenden Link verfügbare Browser-Plug-in
              herunterladen und installieren:{" "}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                rel="noopener noreferrer"
                target="_blank"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              (4) Diese Website verwendet Google Analytics mit der Erweiterung
              „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
              weiterverarbeitet, eine Personenbeziehbarkeit kann damit
              ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
              Personenbezug zukommt, wird dieser also sofort ausgeschlossen und
              die personenbezogenen Daten damit umgehend gelöscht.
            </p>
            <p>
              (5) Wir nutzen Google Analytics, um die Nutzung unserer Website
              analysieren und regelmäßig verbessern zu können. Über die
              gewonnenen Statistiken können wir unser Angebot verbessern und für
              Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle,
              in denen personenbezogene Daten in die USA übertragen werden, hat
              sich Google dem EU-US Privacy Shield unterworfen,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/EU-US-Framework"
              >
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              . Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6
              Abs. 1 S. 1 lit. f DSGVO.
            </p>
            <p>
              (6) Informationen des Drittanbieters: Google Dublin, Google
              Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax:
              +353 (1) 436 1001. Nutzerbedingungen:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.google.com/analytics/terms/de.html"
              >
                http://www.google.com/analytics/terms/de.html
              </a>
              , Übersicht zum Datenschutz:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.google.com/intl/de/analytics/learn/privacy.html"
              >
                http://www.google.com/intl/de/analytics/learn/privacy.html
              </a>
              , sowie die Datenschutzerklärung:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.google.de/intl/de/policies/privacy"
              >
                http://www.google.de/intl/de/policies/privacy
              </a>
              .
            </p>
          </div>

          {/* Kontakt */}
          <div>
            <h3 className={classes.headline}>
              KONTAKT ZUM DATENSCHUTZBEAUFTRAGTEN
            </h3>
            <p>
              Der Datenschutz beim Benutzen dieser Website ist Abbott ein
              wichtiges Anliegen. Die ständige Entwicklung des Internets macht
              von Zeit zu Zeit Anpassungen unserer Datenschutzerklärung
              erforderlich. Wir behalten uns vor, jederzeit entsprechende
              Änderungen vorzunehmen. Sollten Sie Fragen oder Anregungen
              bezüglich der Verarbeitung Ihrer personenbezogenen Daten haben,
              wenden Sie sich bitte an den Datenschutzbeauftragten unter{" "}
              <a href="mailto:datenschutz.de@abbott.com">
                datenschutz.de@abbott.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
