/* Anrede, titel, vorname, nachname, mail, klinik */

export const doctorForm = [
  {
    id: "salutation",
    label: "Anrede",
  },
  {
    id: "academic_degree",
    label: "Titel",
  },
  {
    id: "first_name",
    label: "Vorname",
  },
  {
    id: "last_name",
    label: "Nachname",
    colSm: 6,
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "company",
    label: "Klinik / Praxis",
  },
];

export const debitForm = [
  {
    id: "bic",
    label: "BIC (Swift-Code)",
    required: true,
  },
  {
    id: "iban",
    label: "IBAN",
    required: true,
  },
  {
    id: "depositor",
    label: "Kontoinhaber",
    required: true,
  },
];

export const creditcardForm = [
  {
    id: "number",
    label: "Kartennummer",
    required: true,
    type:"number",
  },
  {
    id: "name",
    label: "Name auf der Karte",
    required: true,
    type:"text",
  },
  {
    id: "date",
    label: "Ablaufdatum",
    required: true,
    type:"date",
  },
];
