import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { doctorForm, debitForm, creditcardForm } from "./paymentDataArrays";
import { DataTable } from "./DataTable";

const useStyles = makeStyles(() => ({
  container: {
    textTransform: "uppercase",
    fontFamily: "brandon-grotesque, sans-serif",
  },
}));

const Confirm = ({ doctor, paymentMethod, paymentData }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={10}>
        <Grid item md={6}>
          <h4>Ihre Daten</h4>
          <DataTable form={doctorForm} data={doctor} />
        </Grid>
        <Grid item md={6}>
          <h4>Ihre Zahlungsdaten</h4>
          {paymentMethod === "debit" && (
            <DataTable form={debitForm} data={paymentData} />
          )}
          {paymentMethod === "creditcard" && (
            <DataTable form={creditcardForm} data={paymentData} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Confirm;
