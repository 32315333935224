// Externe Importe
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Interne Importe
import { HeaderImage } from "../../components";
import Logo from "../../img/grips_logo.jpg"

// Styles
const useStyles = makeStyles(() => ({
  content: {
    padding: "52px 0 150px 0",
  },
  headline: {
      marginTop: "50px",
  },
  text: {
    sfontSize: "15px",
  },
}));

const Imprint = () => {
  // Variables
  const classes = useStyles();

  return (
    <div className="site-wrapper">
      <HeaderImage withCategory={false} />

      <div className={classes.content}>
        <div className="content-wrapper">
          <h1>Impressum</h1>
          <h3 className={classes.headline}>Abbott Medical GmbH</h3>
          <p className={classes.text}>
            <strong>Dienstanbieter:</strong>
            <br />
            Abbott Medical GmbH
            <br />
            Schanzenfeldstraße 2 - 35578 Wetzlar
            <br />
            Tel: +49 6441 87075-0
            <br />
            Fax: +49 6441 87075-606
            <br />
          </p>
          <p className={classes.text}>
            <strong>Form und Sitz der Gesellschaft:</strong>
            <br />
            Gesellschaft mit beschränkter Haftung
            <br />
            Wiesbaden (Hauptniederlassung)
            <br />
            Amtsgericht Wiesbaden HR B 22669
            <br />
          </p>
          <p className={classes.text}>
            <strong>Geschäftsführer:</strong>
            <br />
            Dr. Angela Germer
            <br />
            Matthias Brumm
            <br />
            Robert Emmett Funck
            <br />
          </p>
          <p>
            <strong style={{wordWrap: "break-word"}}>Umsatzsteueridentifikationsnummer:</strong>
            <br />
            DE 811871646
            <br />
          </p>
          <h3 className={classes.headline}>{"Konzeption & Realisierung"}</h3>
          <a href="http://www.grips-design.de" target="_blank" rel="noopener noreferrer">
            <img
              src={Logo}
              width="150"
              height="56"
              alt="GRIPS DESIGN"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
