import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";

import { BLACK, PRIMARY, STEPPERGRAY, WHITE } from "../../styles/colors";

const useStyles = makeStyles(() => ({
  stepLabel: {
    fontFamily: "brandon-grotesque",
    textTransform: "uppercase",
    fontSize: ".875rem",
  },
  stepper: {
    maxWidth: "700px",
    margin: "auto",
    marginBottom: "70px",
    padding: 0,
  },
}));

const StyledConnector = withStyles({
  line: {
    borderColor: STEPPERGRAY,
    borderTopWidth: 2,
  },
  alternativeLabel: {
    top: 21,
    left: "calc(-50% + 32px)",
    right: "calc(50% + 32px)",
  },
})(StepConnector);

const stepperIconStyles = makeStyles({
  root: {
    width: 42,
    height: 42,
    borderRadius: "50%",
    backgroundColor: WHITE,
    border: "2px solid " + STEPPERGRAY,
    justifyContent: "center",
    display: "flex",
    fontFamily: "brandon-grotesque",
    fontSize: "14px",
    alignItems: "center",
    color: "#7f7f7f",
  },
  active: {
    border: "2px solid " + PRIMARY,
    zIndex: 2,
    color: BLACK,
  },
  completed: {
    width: 42,
    height: 42,
    color: WHITE,
    backgroundColor: PRIMARY,
    borderRadius: "50%",
    border: "2px solid " + PRIMARY,
    zIndex: 1,
    fontSize: 18,
    padding: 8,
  },
});

function StepIcon(props) {
  const classes = stepperIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div>{props.icon}</div>
      )}
    </div>
  );
}

function StyledStepper(props) {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={props.activeStep}
      alternativeLabel
      connector={<StyledConnector />}
      className={classes.stepper}
    >
      {props.steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon} className={classes.stepLabel}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StyledStepper;
