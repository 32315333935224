import { format } from "date-fns";
import { getTime, getLongDate } from "../../utils/dateFormatting";

export const getDateString = (dateFrom, dateTo, timeFrom, timeTo) => {
  let result = "";
  dateFrom = checkDate(dateFrom);
  dateTo = checkDate(dateTo);
  timeFrom = checkTime(timeFrom);
  timeTo = checkTime(timeTo);

  if (dateFrom === dateTo || dateTo === "") {
    result = dateFrom + " " + getTimeString(timeFrom, timeTo);
  } else {
    result = dateFrom;
    if (timeFrom !== "") {
      result = result + ", " + timeFrom + "Uhr - ";
    } else {
      result = result + " - ";
    }
    result = result + dateTo;
    if (timeTo !== "") {
      result = result + ", " + timeTo + "Uhr";
    }
  }
  return result;
};

const checkDate = (date) => {
  if (date) {
    date = getLongDate(date);
  } else {
    date = "";
  }
  return date;
};

const checkTime = (time) => {
  if (time) {
    time = getTime(time);
  } else {
    time = "";
  }
  return time;
};

const getTimeString = (timeFrom, timeTo) => {
  if (timeFrom !== "" && timeTo !== "") {
    return timeFrom + " - " + timeTo + "Uhr";
  } else if (timeFrom !== "" && timeTo === "") {
    return timeFrom + "Uhr";
  } else {
    return "";
  }
};

export const getProgramm = (items) => {
  items = sortByDate(items);
  const dates = getAllDates(items);

  const classes = {
    programmHeading: {
      fontSize: 16 ,
      fontWeight: 700,
    },
  };

  return (
    <div>
      {dates.map((date) => (
        <div key={date} style={{ marginBottom: "20px" }}>
          <h5
            style={classes.programmHeading}
          >
            {getLongDate(date)}
          </h5>
          <div style={{ columnWidth: "300px" }}>
            {items.map((item) => (
              <p key={item.id}>
                {item.date_from === date
                  ? getTime(item.time_from) +
                    " " +
                    (item.item_description ? item.item_description : "")
                  : null}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const sortByDate = (items) => {
  const today = format(new Date(), "yyyy-MM-dd");
  items = items.sort(function (item1, item2) {
    if (new Date(item1.date_from) > new Date(item2.date_from)) return 1;
    if (new Date(item1.date_from) < new Date(item2.date_from)) return -1;

    if (
      new Date(today + " " + getTime(item1.time_from)) >
      new Date(today + " " + getTime(item2.time_from))
    )
      return 1;
    if (
      new Date(today + " " + getTime(item1.time_from)) <
      new Date(today + " " + getTime(item2.time_from))
    )
      return -1;

    return 0;
  });
  return items;
};

const getAllDates = (items) => {
  const allDates = [];
  for (var i = 0; i < items.length; i++) {
    if (!allDates.includes(items[i].date_from)) {
      allDates.push(items[i].date_from);
    }
  }
  return allDates;
};
