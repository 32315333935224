import TableCell from '@material-ui/core/TableCell';
import { withStyles, } from '@material-ui/core/styles';

const NormalTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#fff",
        color: "#1a1a1a",
        lineHeight: "normal",
        fontWeight: "400",
        padding: "0 .75rem .75rem .75rem"
    },
    body: {
        fontSize: "14px",
        backgroundColor: "#f2f2f2",
        color: "#1a1a1a",
        padding: ".75rem",
    },
    root: {
        borderBottom: "none",
    }
}))(TableCell);

export default NormalTableCell