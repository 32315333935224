// Externe Importe
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import ReactToPrint from "react-to-print";

// Interne Importe
import http from "../../utils/http-common";
import { HeaderImage, EmphasizedButton, IconButton } from "../../components";
import EventPrint from "../eventDetail/EventPrint";
import EnrollWithPayment from "./EnrollWithPayment";
import EnrollWithoutPayment from "./EnrollWithoutPayment";

// Styles
const useStyles = makeStyles(() => ({
  content: {
    padding: "52px 0 150px 0",
  },
  buttonSection: {
    paddingBottom: 18,
  },
  centerVertically: {
    display: "flex",
    alignItems: "center",
  },
  iconCol: {
    float: "right",
    textAlign: "right",
  },
  headline: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "18.72px",
    marginBottom: "50px",
  },
}));

const EnrollContainer = () => {
  // Variables
  const classes = useStyles();
  const [doctor, setDoctor] = useState({});
  let params = useParams();
  const [congress, setCongress] = useState({});
  const history = useHistory();
  const componentRef = useRef();

  // Data fetching
  useEffect(() => {
    const fetchDoctor = async () => {
      await http
        .get(`/doctor/${params.event_id}/${params.doctor_id}/${params.hash}`)
        .then(function (response) {
          if (response.data.result === null) {
            history.push("/fehler401");
          } else {
            //console.log(response.data.result);
            setDoctor(response.data.result);
          }
        });
    };
    fetchDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const fetchEvent = async () => {
      await http
        .get(`/congress/${params.event_id}/${params.doctor_id}/${params.hash}`)
        .then(function (response) {
          if (response.data.result === null) {
            history.push("/fehler401");
          } else {
            //console.log(response.data.result);
            setCongress(response.data.result);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    if (params) {
      fetchEvent();
    }
  }, [params, history]);

  return (
    <div className="site-wrapper">
      <HeaderImage withCategory={true} color={congress.category_color} />
      <div className={classes.content}>
        <div className="content-wrapper">
          <Row className={classes.buttonSection}>
            <Col className={classes.centerVertically} sm={8} xs={6}>
              <EmphasizedButton
                startIcon={<ArrowBackIosSharpIcon />}
                onClick={() => history.goBack()}
              >
                Zurück
              </EmphasizedButton>
            </Col>
            <Col>
              <div className={classes.iconCol}>
                <IconButton aria-label="Seite drucken" size="small">
                  <ReactToPrint
                    trigger={() => <PrintSharpIcon />}
                    content={() => componentRef.current}
                  />
                </IconButton>
              </div>
            </Col>
          </Row>
          <h3 className={classes.headline}>Anmelden</h3>
          {congress.costs !== "" || congress.costs !== null ? (
            <EnrollWithPayment congress={congress} doctor={doctor} />
          ) : (
            <EnrollWithoutPayment congress={congress} doctor={doctor} />
          )}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} event={congress} />
      </div>
    </div>
  );
};

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <EventPrint event={props.event} />
    </div>
  );
});

export default EnrollContainer;
