// Externe Importe
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

// Interne Importe
import http from "../../utils/http-common";
import {
  EmphasizedButton,
  EventBoxDetail,
} from "../../components";
import Finish from "./steps/Finish";
import Form from "./steps/Form";

// Styles
const useStyles = makeStyles(() => ({
  stepperButtonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "32px",
  },
}));

const EnrollWithoutPayment = ({ congress, doctor }) => {
  // Variables
  const classes = useStyles();
  let params = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();

  const handleConfirm = async () => {
    await http
      .post(`/payment/`, {
        params: {
          event_id: params.event_id,
          doctor_id: params.doctor_id,
          hash: params.hash,
        },
      })
      .then(function (response) {
        if (response.data.result === null) {
          history.push("/fehler401");
        } else {
          setActiveStep(1);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {activeStep < 1 ? (
        <div>
          <Grid container spacing={10}>
            <Grid item lg={9} sm={12}>
              <Form doctor={doctor} />
            </Grid>
            <EventBoxDetail event={congress} />
          </Grid>

          <div className={classes.stepperButtonSection}>
            <EmphasizedButton
              onClick={handleConfirm}
              className={classes.buttonMargin}
            >
              Jetzt anmelden
            </EmphasizedButton>
          </div>
        </div>
      ) : (
        <Finish />
      )}
    </div>
  );
};

export default EnrollWithoutPayment;
