import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {CorporateButton} from "../index";
import CallMadeSharpIcon from "@material-ui/icons/CallMadeSharp";
import {makeStyles} from "@material-ui/core/styles";

import Portal1 from "../../img/portal_at.jpg";
import Portal2 from "../../img/portal_coliquio.jpg";
import Portal3 from "../../img/portal_hh.jpg";





const useStyles = makeStyles((theme) => ({
    portal: {
        backgroundColor: "#fff",
        padding: "30px",
        boxShadow: "none",
        borderRadius: 0,
        textAlign: "center"
    },
    portalP: {
        margin: "10px 0 20px 0",
    }
}));


const Portals = () => {
    const classes = useStyles();

    return (
        <div id="wissensportale">
            <div className="content-wrapper">
                <h3 className="headings">Wissens-Portale</h3>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} xs={12}>
                        <Paper className={classes.portal}>
                            <img src={Portal1} alt="ADVANCEDHEARTTHERAPIES.COM" height="67px" width="212px"/>
                            <p className={classes.portalP}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
                            <CorporateButton
                                className={classes.buttonDark}
                                endIcon={<CallMadeSharpIcon></CallMadeSharpIcon>}
                                href={'https://www.advancedhearttherapies.com/de-de/'}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                zur Website
                            </CorporateButton>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <Paper className={classes.portal}>
                            <img src={Portal2} alt="ADVANCEDHEARTTHERAPIES.COM" height="67px" width="190px"/>
                            <p className={classes.portalP}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
                            <CorporateButton
                                className={classes.buttonDark}
                                endIcon={<CallMadeSharpIcon></CallMadeSharpIcon>}
                                href={'https://www.coliquio.de/'}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                zur Website
                            </CorporateButton>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.portal}>
                            <img src={Portal3} alt="ADVANCEDHEARTTHERAPIES.COM" height="67px" width="100%" style={{objectFit: "scale-down"}}/>
                            <p className={classes.portalP}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
                            <CorporateButton
                                className={classes.buttonDark}
                                endIcon={<CallMadeSharpIcon></CallMadeSharpIcon>}
                                href={'https://www.herzklappenhilfe.de/home/'}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                zur Website
                            </CorporateButton>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Portals;