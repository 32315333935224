// Externe Importe
import React from "react";
import PropTypes from "prop-types";
import Layout from "../../img/layout.svg";
import Header from "../../img/startseite_header.jpg";
import HeaderSW from "../../img/startseite_header_sw.jpg";

const HeaderImage = ({ withCategory, categoryName, color }) => {
  const colorOverlay =  {
      background: `-webkit-linear-gradient(${color}4D, ${color}4D), url(${HeaderSW})`,
      filter: "saturate(200%)",
  }

  const normalHeader = {
    backgroundImage: `url(${Header})`,
  }

  return (
    <div id="header-image" style={withCategory ? colorOverlay : normalHeader}>
      <div className="layout-wrapper">
        <div className="content-wrapper">
          <h2>
            Veranstaltungs-Kalender
            {withCategory === true ? (
              <span className="titleSpan">{categoryName || ""}</span>
            ) : null}
          </h2>
        </div>
        <img className="layout-helper" src={Layout} alt="Layout Helper" />
      </div>
    </div>
  );
};

HeaderImage.propTypes = {
  withCategory: PropTypes.bool.isRequired,
  categoryName: PropTypes.string,
  color: PropTypes.string,
};

export default HeaderImage;
