import { withStyles } from "@material-ui/core/styles";
import { PRIMARY } from "../../styles/colors";
import TextField from "@material-ui/core/TextField";

const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: PRIMARY,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: PRIMARY,
      },
    },
  },
})(TextField);

export default StyledInput;
