import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Header, Footer } from "./components";
import {
  Start,
  Events,
  EventDetail,
  Contact,
  About,
  Imprint,
  Privacy,
  EnrollContainer,
  Error401,
} from "./pages";
import http from "./utils/http-common";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then(function (response) {
          setCategories(response.data.categories);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchCategories();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Header categories={categories} />

      <div className="content">
        <Switch>
          <Route path="/events/:id" component={Events} />
          <Route path="/start" component={Start} />
          <Route
            path="/veranstaltung/:event_id/:doctor_id/:hash"
            component={EventDetail}
          />
          <Route path="/ueber-uns" component={About} />
          <Route path="/kontakt" component={Contact} />
          <Route path="/impressum" component={Imprint} />
          <Route path="/datenschutz" component={Privacy} />
          <Route
            path="/anmelden/:event_id/:doctor_id/:hash"
            component={EnrollContainer}
          />
          <Route path="/fehler401" component={Error401} />
          <Redirect exact from="/" to="/start" />
        </Switch>
      </div>

      <Footer categories={categories} />
    </Router>
  );
}

export default App;
