import { format } from "date-fns";
import deLocale from "date-fns/locale/de";

export const getTime = (time) => {
  return format(new Date(time), "HH:mm");
};

export const getLongDate = (date) => {
    return format(new Date(date), "EEEE', 'dd. LLLL yyyy", {
        locale: deLocale
    })
};
