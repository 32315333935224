import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  firstCol: {
    paddingRight: "30px",
  },
}));

const mapSalutation = (salutation) => {
  switch (salutation) {
    case "mr":
      return "Herr";
    case "mrs":
      return "Frau";
    default:
      return "";
  }
};

const generateRows = (form, data, classes) =>
  form.map((field) => {
    if (field.id === "salutation") {
      return (
        <tr key={field.id}>
          <td className={classes.firstCol}>{field.label}</td>
          <td>{data[field.id] && mapSalutation(data[field.id])}</td>
        </tr>
      );
    }
    if (field.id === "company") {
      return (
        <tr key={field.id}>
          <td className={classes.firstCol}>{field.label}</td>
          <td>
            {data.companies && data.companies[0]
              ? data.companies[0].company_name
              : ""}
          </td>
        </tr>
      );
    }
    if (field.id === "date") {
      return (
        <tr key={field.id}>
          <td className={classes.firstCol}>{field.label}</td>
          <td>
            {data[field.id] && format(new Date(data[field.id]), "MM-yyyy")}
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={field.id}>
          <td className={classes.firstCol}>{field.label}</td>
          <td style={{ wordBreak: "break-all" }}>{data[field.id] || ""}</td>
        </tr>
      );
    }
  });

export const DataTable = ({ form, data }) => {
  const classes = useStyles();

  return (
    <table>
      <tbody>{generateRows(form, data, classes)}</tbody>
    </table>
  );
};
