import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row } from "react-bootstrap";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import { EmphasizedButton, SecondaryButton, StyledInput } from "..";
import { doctorForm, doctorData } from "../../utils/contactData";
import { FormControl } from "@material-ui/core";
import { PRIMARY, WHITE } from "../../styles/colors";
import http from "../../utils/http-common";

const useStyles = makeStyles(() => ({
  required: {
    fontSize: "14px",
    marginTop: "16px",
    color: "#0000008a",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 32px 10.5px 14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: PRIMARY,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: PRIMARY,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "&:focus .MuiSelect-select": {
      backgroundColor: WHITE,
    },
  },
}));

const ContactDialog = ({ isOpen, handleClose, eventId }) => {
  const classes = useStyles();
  const [values, setvalues] = useState(doctorData);
  const [error, setError] = useState("");

  useEffect(() => {
    setvalues({ ...values, event_id: eventId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const checkFilled = () => {
    doctorForm.forEach((field) => {
      if (field.required === true && values[field.id] === "") {
        setError((error) => ({
          ...error,
          [field.id]: "Pflichtfeld",
        }));
      }
    });
  };

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    checkFilled();

    if (isEmpty(error)) {
      await http
        .post(`/contact`, values)
        .then(function (response) {
          handleClose();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleChange = (fieldId) => (event) => {
    setvalues({ ...values, [fieldId]: event.target.value });
    removeError(fieldId);

    doctorForm.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...error,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const handleOnBlur = (fieldId) => (event) => {
    removeError(fieldId);
    doctorForm.forEach((field) => {
      if (
        fieldId === field.id &&
        !document.getElementById(fieldId).validity.valid
      ) {
        if (field.required === true) {
          setError({
            ...error,
            [fieldId]: "Pflichtfeld",
          });
        }
        if (field.type === "email") {
          setError({ ...error, [fieldId]: "Ungültige Emailadresse." });
        }
      }
    });
  };

  const removeError = (fieldId) => {
    const { [fieldId]: tmp, ...rest } = error;
    setError(rest);
  };

  const generateForm = (form) =>
    form.map((field) => {
      if (field.type === "select") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <FormControl
              style={{ marginTop: 12, width: "100%", borderRadius: 0 }}
            >
              <TextField
                variant="outlined"
                required={field.required}
                value={values[field.id]}
                className={classes.select}
                select
                label={field.label}
                id={field.id}
                onChange={handleChange(field.id)}
              >
                {field.options.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Col>
        );
      }
      if (field.type === "textarea") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <FormControl style={{ marginTop: 12, width: "100%" }}>
              <StyledInput
                label={field.label}
                variant="outlined"
                size="small"
                type={field.type}
                multiline
                rows={3}
                required={field.required}
                value={values[field.id]}
                onChange={handleChange(field.id)}
                onBlur={handleOnBlur(field.id)}
                id={field.id}
              />
              {error[field.id] && (
                <FormHelperText error id={"error-text-" + field.id}>
                  {error[field.id]}
                </FormHelperText>
              )}
            </FormControl>
          </Col>
        );
      } else {
        if (field.id === "email") {
          return (
            <Col
              key={field.id}
              lg={field.colLg}
              md={field.colMd}
              sm={field.colSm}
              style={{display: "none"}}
            >
              <FormControl style={{ marginTop: 12, width: "100%" }}>
                <StyledInput
                  label={field.label}
                  variant="outlined"
                  size="small"
                  type={field.type}
                  required={field.required}
                  value={values[field.id]}
                  onChange={handleChange(field.id)}
                  onBlur={handleOnBlur(field.id)}
                  id={field.id}
                />
                {error[field.id] && (
                  <FormHelperText error id={"error-text-" + field.id}>
                    {error[field.id]}
                  </FormHelperText>
                )}
              </FormControl>
            </Col>
          );
        } else {
          return (
            <Col
              key={field.id}
              lg={field.colLg}
              md={field.colMd}
              sm={field.colSm}
            >
              <FormControl style={{ marginTop: 12, width: "100%" }}>
                <StyledInput
                  label={field.label}
                  variant="outlined"
                  size="small"
                  type={field.type}
                  required={field.required}
                  value={values[field.id]}
                  onChange={handleChange(field.id)}
                  onBlur={handleOnBlur(field.id)}
                  id={field.id}
                />
                {error[field.id] && (
                  <FormHelperText error id={"error-text-" + field.id}>
                    {error[field.id]}
                  </FormHelperText>
                )}
              </FormControl>
            </Col>
          );
        }
      }
    });

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>Kontakt</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Gerne senden wir Ihnen Infos zu dem gewünschten Event zu. Füllen Sie
          hierzu einfach nur das Formular aus.
        </DialogContentText>
        <form>
          <Row>{generateForm(doctorForm)}</Row>
        </form>
        <div className={classes.required}>* Pflichtfeld</div>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose} color="primary">
          Abbrechen
        </SecondaryButton>
        <EmphasizedButton onClick={handleSubmit} color="primary">
          OK
        </EmphasizedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDialog;
