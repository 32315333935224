import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import { PRIMARY } from "../../styles/colors";

const BlueRadio = withStyles({
  root: {
    color: PRIMARY,
    "&$checked": {
      color: PRIMARY,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default BlueRadio;
