import React from "react";
import { EmphasizedButton } from "../../../components";

import Checkmark from "../../../img/finish.jpg";

const Finish = () => {
  return (
    <div style={{textAlign: "center"}}>
      <img src={Checkmark} style={{paddingBottom: "10px"}} alt="Grünes Häckchen"/>
      <h3 style={{fontWeight: "bold", marginBottom: "50px"}}>Anmeldung erfolgreich</h3>
      <p style={{textTransform: "uppercase", fontFamily: "brandon-grotesque,sans-serif", marginBottom: "32px"}}>
        Vielen Dank für ihre Anmeldung. Sie erhalten in Kürze eine
        Bestätigungs-Email.
      </p>
      <EmphasizedButton>Zur Startseite</EmphasizedButton>
    </div>
  );
};

export default Finish;
