import IconButton from '@material-ui/core/IconButton';
import { withStyles, } from '@material-ui/core/styles';
import { MEDIUMGRAY } from '../../styles/colors';
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const StyledIconButton = withStyles(()=> ({
    root: {
        color: MEDIUMGRAY,
        padding: 6,
        fontSize: 30,
        "&:hover": {
            backgroundColor: emphasize(MEDIUMGRAY, 0.92),
            boxShadow: "none",
        },
    },
    sizeSmall: {
        '& svg':{
            fontSize: 30,
        },
    }
}))(IconButton);

export default StyledIconButton;