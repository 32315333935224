// Externe Importe
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import DirectionsSharpIcon from "@material-ui/icons/DirectionsSharp";
import LanguageSharpIcon from "@material-ui/icons/LanguageSharp";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactHtmlParser from "react-html-parser";
import ReactToPrint from "react-to-print";

// Interne Importe
import http from "../../utils/http-common";
import {
  Portals,
  EmphasizedButton,
  IconButton,
  HeaderImage,
  RelatedEvents,
} from "../../components";
import { LIGHTGRAY, PRIMARY } from "../../styles/colors";
import EnrollCol from "./EnrollCol";
import { getDateString, getProgramm } from "./dateHelper";
/* import { getTime } from "../../utils/dateFormatting"; */
import "./wysiwyg-styles.css";
import EventPrint from "./EventPrint";

// Styles
const useStyles = makeStyles((theme) => ({
  content: {
    padding: "52px 0 6.5rem 0",
  },
  iconCol: {
    float: "right",
    textAlign: "right",
  },
  buttonSection: {
    paddingBottom: 18,
  },
  eventTitle: {
    fontWeight: 700,
    fontSize: "3.8rem",
    marginTop: "2rem",
    marginBottom: "3.2rem",
    lineHeight: "4rem",
  },
  eventTitleSmall: {
    fontWeight: 600,
    fontSize: "2rem",
    marginTop: "2rem",
    marginBottom: "3.2rem",
    lineHeight: "2.5rem",
  },
  centerVertically: {
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    marginTop: 0,
    marginBottom: "1.5rem",
    letterSpacing: 0.6,
    fontWeight: 700,
  },
  greyContainer: {
    marginTop: 18,
    backgroundColor: LIGHTGRAY,
    padding: "1rem",
    lineHeight: "20px",
    width: "inherit",
  },
  link: {
    color: PRIMARY,
    textTransform: "uppercase",
    fontSize: ".875rem",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
    fontFamily: "brandon-grotesque,sans-serif",
  },
  tightText: {
    paddingBottom: 12,
  },
  greyCol: {
    display: "flex",
  },
}));

const EventDetail = () => {
  const classes = useStyles();
  let params = useParams();
  const [congress, setCongress] = useState({});
  const history = useHistory();
  const small = useMediaQuery("(min-width:600px)");
  const componentRef = useRef();

  const generateGoogleMapsUrl = (address) => {
    let url = "https://www.google.de/maps/place/";
    if (address.street) {
      url = url + address.street.split(" ").join("+") + "+";
    }
    if (address.post_code) {
      url = url + address.post_code + "+";
    }
    if (address.city) {
      url = url + address.city.split(" ").join("+") + "+";
    }
    return url;
  };

  // data fetching
  useEffect(() => {
    const fetchEvent = async () => {
      await http
        .get(`/congress/${params.event_id}/${params.doctor_id}/${params.hash}`)
        .then(function (response) {
          if (response.data.result === null) {
            history.push("/fehler401");
          } else {
            console.log(response.data.result);
            setCongress(response.data.result);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    if (params) {
      fetchEvent();
    }
  }, [params, history]);

  return (
    <div className="site-wrapper">
      {/* Header */}
      {congress && congress.category_name ? (
        <HeaderImage
          withCategory={true}
          categoryName={congress.category_name}
          color={congress.category_color}
        />
      ) : (
        <HeaderImage withCategory={false} />
      )}

      {/* Content */}
      <div className={classes.content}>
        <div className="content-wrapper">
          {/* Top Button Section */}
          <Row className={classes.buttonSection}>
            <Col className={classes.centerVertically} sm={8} xs={6}>
              <EmphasizedButton
                startIcon={<ArrowBackIosSharpIcon />}
                onClick={() => history.goBack()}
              >
                Zurück
              </EmphasizedButton>
            </Col>
            <Col>
              <div className={classes.iconCol}>
                <IconButton aria-label="Seite drucken" size="small">
                  <ReactToPrint
                    trigger={() => <PrintSharpIcon />}
                    content={() => componentRef.current}
                  />
                </IconButton>
              </div>
            </Col>
          </Row>

          {/* Event */}
          <Row>
            <Col lg={8} md={12}>
              <h1
                style={{ wordWrap: "break-word" }}
                className={small ? classes.eventTitle : classes.eventTitleSmall}
              >
                {congress && congress.event_name}
              </h1>
              <h3 className={classes.subHeading}>Beschreibung</h3>
              <div style={{ marginBottom: "50px" }} id="wysiwyg">
                {congress && congress.description
                  ? ReactHtmlParser(congress.description)
                  : ""}
              </div>

              {/* Programm */}
              <div className={classes.greyContainer}>
                <h3 className={classes.subHeading}>Programm</h3>
                {congress && congress.items
                  ? getProgramm(congress.items)
                  : null}
              </div>
            </Col>

            {/* Rechte Spalte */}
            <Col lg={4} md={12} className={classes.greyCol}>
              <Row>
                {/* Wann und wo */}
                <Col lg={12} md={6} sm={6} xs={12}>
                  <div className={classes.greyContainer}>
                    <h3 className={classes.subHeading}>Wann und Wo</h3>
                    <Row>
                      <Col md={6} sm={12} xs={12}>
                        Wann:{" "}
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        {congress && congress.date_from
                          ? getDateString(
                              congress.date_from,
                              congress.date_to,
                              congress.time_from,
                              congress.time_to
                            )
                          : ""}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "1.5rem" }}>
                      <Col md={6} sm={12} xs={12}>
                        Veranstaltungsort:{" "}
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        {congress && congress.addresses ? (
                          <div>
                            {congress.addresses[0].address_name ? (
                              <span>
                                {congress.addresses[0].address_name}
                                <br />
                              </span>
                            ) : null}
                            {congress.addresses[0].street && (
                              <span>
                                {congress.addresses[0].street}
                                <br />
                              </span>
                            )}
                            {congress.addresses[0].post_code}{" "}
                            {congress.addresses[0].city}
                          </div>
                        ) : (
                          ""
                        )}
                        {congress &&
                        congress.addresses &&
                        congress.addresses[0] ? (
                          <a
                            className={classes.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={generateGoogleMapsUrl(congress.addresses[0])}
                          >
                            <DirectionsSharpIcon
                              fontSize="small"
                              style={{ marginRight: "5px" }}
                            />
                            Route berechnen
                          </a>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </Col>

                {/* Referenten */}
                <Col lg={12} md={6} sm={6} xs={12} className={classes.greyCol}>
                  <div className={classes.greyContainer}>
                    <h3 className={classes.subHeading}>Referenten</h3>
                    {congress &&
                    congress.speakers &&
                    congress.speakers.length > 0 ? (
                      <div>
                        {congress.speakers.map((speaker) => (
                          <div key={speaker.id} className={classes.tightText}>
                            {speaker.speaker}
                            <br />
                            <i>{speaker.company_name}</i>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </Col>

                {/* Veranstalter */}

                <Col lg={12} md={6} sm={6} xs={12} className={classes.greyCol}>
                  <div className={classes.greyContainer}>
                    <h3 className={classes.subHeading}>Veranstalter</h3>
                    {congress && congress.organizer ? (
                      <div>
                        <div className={classes.tightText}>
                          {congress.organizer.organizer_name && (
                            <span>
                              {congress.organizer.organizer_name}
                              <br />
                            </span>
                          )}
                          {congress.organizer.contact_person_first_name ||
                          congress.organizer.contact_person_last_name ? (
                            <span>
                              {congress.organizer.contact_person_first_name}{" "}
                              {congress.organizer.contact_person_last_name}
                              <br />
                            </span>
                          ) : null}

                          {congress.organizer.organizer_street && (
                            <span>
                              {congress.organizer.organizer_street}
                              <br />
                            </span>
                          )}
                          {congress.organizer.organizer_post_code ||
                          congress.organizer.organizer_city ? (
                            <span>
                              {congress.organizer.organizer_post_code}{" "}
                              {congress.organizer.organizer_city}
                            </span>
                          ) : null}
                        </div>
                        {congress.organizer.organizer_web && (
                          <a
                            className={classes.link}
                            href={congress.organizer.organizer_web}
                            rel="noopener noreferrer"
                          >
                            <LanguageSharpIcon
                              fontSize="small"
                              style={{ marginRight: "5px" }}
                            />
                            Website
                          </a>
                        )}
                      </div>
                    ) : null}
                  </div>
                </Col>

                {/* Sponsoren */}
                <Col lg={12} md={6} sm={6} xs={12} className={classes.greyCol}>
                  <div className={classes.greyContainer}>
                    <h3 className={classes.subHeading}>Sponsoren</h3>
                    {congress &&
                    congress.sponsors &&
                    congress.sponsors.length > 0 ? (
                      <Row>
                        {congress.sponsors.map((sponsor) => (
                          <Col
                            lg={6}
                            md={4}
                            sm={6}
                            xs={6}
                            key={sponsor.id}
                            style={{
                              margin: "9px 0",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href={sponsor.website}
                              style={{ alignSelf: "center" }}
                            >
                              <img
                                src={sponsor.baseImage}
                                alt={"Logo " + sponsor.name}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "70px",
                                }}
                              />
                            </a>
                          </Col>
                        ))}
                      </Row>
                    ) : null}
                  </div>
                </Col>

                <EnrollCol
                  color={congress ? congress.category_color : "000000"}
                  price={congress ? congress.costs : "0"}
                  params={params}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      {/* Links */}
      {congress && congress.category_id ? (
        <RelatedEvents category_id={congress.category_id} />
      ) : null}
      <Portals />
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} event={congress} />
      </div>
    </div>
  );
};

const ComponentToPrint = React.forwardRef((props, ref) => { 
  return (
    <div ref={ref}>
      <EventPrint event={props.event} />
    </div>
  );
});

export default EventDetail;
