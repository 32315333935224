import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  EventBox,
  Portals,
  FilterableTableHead,
  NormalTableCell,
  StyledTableRow,
  CorporateButton,
  FormatDate,
  HeaderImage,
  ContactDialog,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";

import http from "../../utils/http-common";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { TITLEFONT } from "../../styles/colors";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
/* import EventSharpIcon from "@material-ui/icons/EventSharp"; */
import AppsSharpIcon from "@material-ui/icons/AppsSharp";
import clsx from "clsx";
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleSpan: {
    fontWeight: 400,
    float: "right",
    display: "block",
  },
  content: {
    padding: "6.5rem 0 150px 0",
  },
  titleGrid: {
    justifyContent: "flex-end",
    fontFamily: TITLEFONT,
    textTransform: "uppercase",
  },
  titlePaper: {
    boxShadow: "none",
    border: 0,
    "& h1": {
      fontSize: "3.8rem",
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: 0,
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
        fontWeight: 600,
        lineHeight: 1,
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 0 6px 0",
    },
  },
  titleRight: {
    alignItems: "flex-end",
    paddingBottom: "16px!important",
    "& .MuiPaper-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  },
  icon: {
    marginLeft: ".5rem",
    padding: "2px",
    color: "#cbcbcb",
    backgroundColor: "#f2f2f2",
    position: "relative",
    cursor: "pointer",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      backgroundColor: "transparent",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      border: "3px solid #f2f2f2",
      transform: "scale(1)",
      transition: "transform .25s",
    },
    "&:hover:before": {
      transform: "scale(1.1)",
    },
    "&:first-child": {
      marginLeft: "15px",
    },
  },
  activeView: {
    backgroundColor: "#0096d6",
    color: "#fff",
    "&::before": {
      borderColor: "#0096d6",
    },
  },
  select: {
    marginLeft: "15px",
    textTransform: "uppercase",
    fontFamily: TITLEFONT,
    width: "150px",
    backgroundColor: "#f2f2f2",
    "&::before": {
      border: "none!important",
    },
    "& .MuiSelect-root": {
      padding: "6px",
    },
  },
  listitem: {
    textTransform: "uppercase",
    fontFamily: TITLEFONT,
  },
  subtitleGrid: {
    marginTop: "30px",
    marginBottom: "50px",
    justifyContent: "space-between",
    fontFamily: TITLEFONT,
    textTransform: "uppercase",
  },
  subtitle: {
    fontWeight: 700,
  },
  pagination: {
    display: "flex",
    alignItems: "center",
  },
  bottomGrid: {
    justifyContent: "flex-end",
    fontFamily: TITLEFONT,
    textTransform: "uppercase",
    marginTop: "50px",
  },
  table: {
    fontFamily: TITLEFONT,
    textTransform: "uppercase",
  },
  buttonLight: {
    backgroundColor: "#fff",
    color: "#000",
    "&::before": {
      borderColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  activeOrder: {
    backgroundColor: "#CEE4EE",
    fontWeight: 700,
  },
  paginationIcon: {
    color: "#000000",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    padding: 0,
  },
}));

const headCells = [
  { id: "events.event_name", label: "Titel", filterable: true, width: "30%" },
  { id: "events.date_from", label: "Datum", filterable: true, width: "17%" },
  { id: "events.city", label: "Ort", filterable: true, width: "12%" },
  {
    id: "topics.topic_name",
    label: "Kategorie",
    filterable: true,
    width: "17%",
  },
  {
    id: "organizers.organizer_name",
    label: "Veranstalter",
    filterable: true,
    width: "25%",
  },
];

const Event = () => {
  const classes = useStyles();
  let { id } = useParams();
  const [events, setEvents] = useState([]);
  const [view, setView] = useState("grid");
  const [actualPage, setActualPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("events.date_from");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
    setActualPage(1);
  };

  const requestEventInfos = (eventId) => {
    setContactDialogOpen(true);
    setSelectedEvent(eventId);
  };

  const handleContactClose = () => {
    setContactDialogOpen(false);
  };

  // Pagination
  const navigateBack = () => {
    setActualPage(actualPage - 1);
  };
  const navigateFurther = () => {
    setActualPage(actualPage + 1);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      await http
        .get(`/congresses/${actualPage}/10`, {
          params: { category_filter: id, sort: orderBy, sort_dir: order },
        })
        .then(function (response) {
          setEvents(response.data.events.data);
          setAllPages(Math.ceil(response.data.events.total / 10));
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchEvents();
  }, [id, order, orderBy, actualPage]);

  return (
    <div className="site-wrapper">
      <HeaderImage
        withCategory={true}
        categoryName={events.length !== 0 ? events[0].category_name : ""}
        color={events.length !== 0 ? events[0].color : ""}
      />
      <div className={classes.content}>
        <div className="content-wrapper">
          <Grid container spacing={3} className={classes.titleGrid}>
            <Grid item lg={7} sm={12} xs={12}>
              <Paper className={classes.titlePaper}>
                <h1>{events.length !== 0 ? events[0].category_name : ""}</h1>
              </Paper>
            </Grid>
            <Grid
              container
              item
              lg={5}
              md={12}
              sm={12}
              xs={12}
              className={classes.titleRight}
            >
              <Grid item xs={12} sm={6}>
                <Paper
                  className={classes.titlePaper}
                  style={matches ? { justifyContent: "flex-start" } : null}
                >
                  Sortierung:
                  <Select
                    labelId="Select for Sorting"
                    id="sort-select"
                    value={orderBy}
                    onChange={(event) => sorting("ASC", event.target.value)}
                    className={classes.select}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem
                      value={"events.event_name"}
                      className={classes.listitem}
                    >
                      Titel
                    </MenuItem>
                    <MenuItem
                      value={"events.date_from"}
                      className={classes.listitem}
                    >
                      Datum
                    </MenuItem>
                    <MenuItem
                      value={"events.city"}
                      className={classes.listitem}
                    >
                      Ort
                    </MenuItem>
                    <MenuItem
                      value={"topics.topic_name"}
                      className={classes.listitem}
                    >
                      Kategorie
                    </MenuItem>
                    <MenuItem
                      value={"organizers.organizer_name"}
                      className={classes.listitem}
                    >
                      Veranstalter
                    </MenuItem>
                  </Select>
                </Paper>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Paper className={classes.titlePaper}>
                  Darstellung:
                  <div
                    className={clsx(
                      classes.icon,
                      view === "grid" ? classes.activeView : ""
                    )}
                    onClick={() => setView("grid")}
                  >
                    <AppsSharpIcon />
                  </div>
                  <div
                    className={clsx(
                      classes.icon,
                      view === "list" ? classes.activeView : ""
                    )}
                    onClick={() => setView("list")}
                  >
                    <FormatListBulletedSharpIcon />
                  </div>
                  {/* <div
                    className={clsx(
                      classes.icon,
                      view === "calendar" ? classes.activeView : ""
                    )}
                    onClick={() => setView("calendar")}
                  >
                    <EventSharpIcon />
                  </div> */}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.subtitleGrid}>
            <Grid item>
              <h3 className={classes.subtitle}>Alle Veranstaltungen</h3>
            </Grid>
            <Grid item className={classes.pagination}>
              <IconButton
                disabled={actualPage === 1 ? true : false}
                className={classes.paginationIcon}
                onClick={navigateBack}
                style={{ marginBottom: ".5rem" }}
              >
                <ChevronLeftSharpIcon />
              </IconButton>
              <span style={{ marginBottom: ".5rem" }}>
                Seite <strong>{actualPage}</strong> von {allPages}
              </span>
              <IconButton
                style={{ marginBottom: ".5rem" }}
                disabled={actualPage === allPages ? true : false}
                className={classes.paginationIcon}
                onClick={navigateFurther}
              >
                <ChevronRightSharpIcon />
              </IconButton>
            </Grid>
          </Grid>

          {view === "grid" && (
            <Grid container spacing={3}>
              {events.map((event) => {
                return <EventBox key={event.id} event={event} contactCallback={requestEventInfos}/>;
              })}
            </Grid>
          )}

          {view === "list" && (
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <FilterableTableHead
                  headCells={headCells}
                  sortFunc={sorting}
                  orderBy={orderBy}
                  setOrderBy={() => setOrderBy}
                />
                <TableBody>
                  {events.map((event) => (
                    <StyledTableRow key={event.id}>
                      <NormalTableCell
                        component="th"
                        scope="row"
                        className={
                          orderBy === "events.event_name"
                            ? classes.activeOrder
                            : ""
                        }
                      >
                        {event.event_name}
                      </NormalTableCell>
                      <NormalTableCell
                        className={
                          orderBy === "events.date_from"
                            ? classes.activeOrder
                            : ""
                        }
                      >
                        <FormatDate date={event.date_from} />
                      </NormalTableCell>
                      <NormalTableCell
                        className={
                          orderBy === "events.city" ? classes.activeOrder : ""
                        }
                      >
                        {event.city}
                      </NormalTableCell>
                      <NormalTableCell
                        className={
                          orderBy === "topics.topic_name"
                            ? classes.activeOrder
                            : ""
                        }
                      >
                        {event.topic_name}
                      </NormalTableCell>
                      <NormalTableCell
                        className={
                          orderBy === "organizers.organizer_name"
                            ? classes.activeOrder
                            : ""
                        }
                      >
                        {event.organizer_name}
                      </NormalTableCell>
                      <NormalTableCell>
                        <CorporateButton
                          className={classes.buttonLight}
                          onClick={() => requestEventInfos(event.id)}
                        >
                          anfragen
                        </CorporateButton>
                      </NormalTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Grid container spacing={3} className={classes.bottomGrid}>
            <Grid item className={classes.pagination}>
              <IconButton
                disabled={actualPage === 1 ? true : false}
                className={classes.paginationIcon}
                onClick={navigateBack}
              >
                <ChevronLeftSharpIcon />
              </IconButton>
              <span>
                Seite <strong>{actualPage}</strong> von {allPages}
              </span>
              <IconButton
                disabled={actualPage === allPages ? true : false}
                className={classes.paginationIcon}
                onClick={navigateFurther}
              >
                <ChevronRightSharpIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
      <ContactDialog
        isOpen={contactDialogOpen}
        handleClose={handleContactClose}
        eventId={selectedEvent}
      />

      <Portals />
    </div>
  );
};

export default Event;
