import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

import { NormalTableCell } from "../../components";

const useStyles = makeStyles(() => ({
    root: {
        "&:hover": {
            color: "#1a1a1a!important",
        },
    },
    active: {
        fontWeight: 700,
        "& svg": {
            color: "#0096d6!important",
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


function FilterableTableHead(props) {
    const classes = useStyles();
    const { headCells, sortFunc, orderBy, setOrderBy } = props;
    const [order, setOrder] = React.useState('asc');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        sortFunc(isAsc ? 'desc' : 'asc', property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    headCell.filterable ?
                        <NormalTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{width: headCell.width}}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                classes={{ active: classes.active, root: classes.root }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'absteigend sortiert' : 'aufsteigend sortiert'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </NormalTableCell> : <NormalTableCell
                            key={headCell.id}
                            style={{width: headCell.width}}
                        >{headCell.label}</NormalTableCell>
                ))}
                <NormalTableCell align="right"> </NormalTableCell>
            </TableRow>
        </TableHead>
    );
}

FilterableTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
    sortFunc: PropTypes.func.isRequired,
};

export default FilterableTableHead;