import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { format, isDate, isValid } from "date-fns";

import {
  LIGHTGRAY,
  SECONDARY,
  PRIMARY_LIGHT,
  PRIMARY,
} from "../../../styles/colors";
import Lastschrift from "../../../img/anmeldeprozess_lastschrift.jpg";
import Kreditkarte from "../../../img/anmeldeprozess_kreditkarte.jpg";
import { StyledInput, BlueRadio } from "../../../components";
import {
  isValidIBANNumber,
  isValidBICNumber,
  isValidCreditCardNumber,
} from "../../../utils/paymentHelper";
import { debitForm, creditcardForm } from "./paymentDataArrays";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid " + LIGHTGRAY,
    marginRight: "1rem",
    marginTop: ".5rem",
    padding: "10px",
  },
  customRadio: {
    margin: 0,
    left: "50%",
  },
  paymentDataContainer: {
    backgroundColor: LIGHTGRAY,
    marginTop: 40,
    fontFamily: "brandon-grotesque, sans-serif",
    textTransform: "uppercase",
    padding: 10,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  inputRoot: {
    borderRadius: 0,
    padding: "10.5px 14px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25) + "!important",
    },
    height: "40px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: PRIMARY,
      },
    },
  },
  input: {
    "& label.Mui-focused": {
      color: PRIMARY,
    },
    "& label": {
      transform: "translate(14px, 12px) scale(1)",
    },
    "& .MuiOutlinedInput-root": {
      padding: "10.5px 14px",
      "& fieldset": {
        borderRadius: 0,
      },
      "&.Mui-focused fieldset": {
        borderColor: PRIMARY,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 0",
    },
  },
}));

const abbottTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: SECONDARY,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiButton: {
      root: {
        color: SECONDARY + "!important",
      },
    },
  },
});

const Payment = ({
  handleNext,
  startValidation,
  callBack,
  badCallback,
  paymentData,
  paymentMethod,
}) => {
  const [value, setValue] = React.useState(paymentMethod);
  const [error, isError] = useState("");
  const classes = useStyles();
  const [debitValues, setDebitValues] = useState({
    bic: "",
    iban: "",
    depositor: "",
  });
  const [debitError, setDebitError] = useState({});
  const [creditcardValues, setCreditcardValues] = useState({
    number: "",
    name: "",
    date: "",
  });
  const [creditcardError, setCreditcardError] = useState({});

  // payment Method
  const handleChange = (event) => {
    setValue(event.target.value);
    isError(undefined);
  };

  useEffect(() => {
    if (Object.keys(paymentData).length > 0) {
      if (paymentMethod === "debit") {
        setDebitValues(paymentData);
      }
      if (paymentMethod === "creditcard") {
        setCreditcardValues(paymentData);
      }
    }
  }, [paymentData, paymentMethod]);

  useEffect(() => {
    if (startValidation === true) {
      if (value === "") {
        isError(true);
        badCallback();
        return;
      }
      if (value === "debit") {
        let check = true;
        debitForm.forEach((field) => {
          if (field.required && debitValues[field.id] === "") {
            setDebitError((debitError) => ({
              ...debitError,
              [field.id]: "Pflichtfeld",
            }));
            check = false;
          } else {
            check = true;
          }
        });

        if (Object.keys(debitError).length === 0 && check === true) {
          callBack(value, debitValues);
          handleNext();
        } else {
          badCallback();
        }
      }
      if (value === "creditcard") {
        let check = true;
        creditcardForm.forEach((field) => {
          if (field.required && creditcardValues[field.id] === "") {
            setCreditcardError((creditcardError) => ({
              ...creditcardError,
              [field.id]: "Pflichtfeld",
            }));
            check = false;
          } else {
            check = true;
          }
        });

        if (Object.keys(creditcardError).length === 0 && check === true) {
          callBack(value, creditcardValues);
          handleNext();
        } else {
          badCallback();
        }
      }
    } else {
      badCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startValidation]);

  //handling changes if method === debit
  const handleDebitChange = (fieldId) => (event) => {
    setDebitValues({ ...debitValues, [fieldId]: event.target.value });
    removeDebitError(fieldId);
  };

  const removeDebitError = (fieldId) => {
    const { [fieldId]: tmp, ...rest } = debitError;
    setDebitError(rest);
  };

  const handleDebitOnBlur = (fieldId) => (event) => {
    removeDebitError(fieldId);
    if (!document.getElementById(fieldId).validity.valid) {
      if (
        document.getElementById(fieldId).required === true &&
        document.getElementById(fieldId).value === ""
      ) {
        setDebitError({ ...debitError, [fieldId]: "Pflichtfeld" });
      } else {
        setDebitError({ ...debitError, [fieldId]: "Fehlerhafte Eingabe" });
      }
    }
    if (fieldId === "iban" && !debitError[fieldId]) {
      // validate IBAN
      if (isValidIBANNumber(event.target.value) === false) {
        setDebitError({ ...debitError, [fieldId]: "Ungültige IBAN" });
      }
    }
    if (fieldId === "bic" && !debitError[fieldId]) {
      // validate BIC
      if (isValidBICNumber(event.target.value) === false) {
        setDebitError({ ...debitError, [fieldId]: "Ungültige BIC" });
      }
    }
  };

  //handling changes if method === creditcard
  const handleCreditcardChange = (fieldId) => (event) => {
    setCreditcardValues({ ...creditcardValues, [fieldId]: event.target.value });
    removeCreditcardError(fieldId);
  };

  const removeCreditcardError = (fieldId) => {
    const { [fieldId]: tmp, ...rest } = creditcardError;
    setCreditcardError(rest);
  };

  const handleCreditcardOnBlur = (fieldId) => (event) => {
    removeCreditcardError(fieldId);
    if (!document.getElementById(fieldId).validity.valid) {
      if (
        document.getElementById(fieldId).required === true &&
        document.getElementById(fieldId).value === ""
      ) {
        setCreditcardError({ ...creditcardError, [fieldId]: "Pflichtfeld" });
      } else {
        setCreditcardError({
          ...creditcardError,
          [fieldId]: "Fehlerhafte Eingabe",
        });
      }
    }
    if (fieldId === "number" && !creditcardError[fieldId]) {
      if (isValidCreditCardNumber(event.target.value) === false) {
        setCreditcardError({
          ...creditcardError,
          [fieldId]: "Ungültige Nummer",
        });
      }
    }
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      if (validateDateFormat(date)) {
        setCreditcardValues({
          ...creditcardValues,
          date: format(date, "yyyy-MM-dd"),
        });
      }
    }
  };

  const handleDateOnBlur = (event) => {
    const date = event.target.value;
    if (date === null || date === "") {
      setCreditcardError({
        ...creditcardError,
        date: "Pflichtfeld",
      });
    }
  };

  const validateDateFormat = (date) => {
    if (isDate(date)) {
      if (isValid(date)) {
        removeCreditcardError("date");
        return true;
      } else {
        setCreditcardError({
          ...creditcardError,
          date: "Bitte geben Sie ein gültiges Datum ein.",
        });
        return false;
      }
    } else {
      setCreditcardError({
        ...creditcardError,
        date: "Pflichtfeld",
      });
      return false;
    }
  };

  const getPaymentForm = (method) => {
    if (method === "debit") {
      return (
        <div>
          <form
            className={classes.form}
            autoComplete="off"
            style={{ marginBottom: 30 }}
          >
            {debitForm.map((field) => (
              <FormControl key={field.id}>
                <StyledInput
                  label={field.label}
                  variant="outlined"
                  size="small"
                  type="text"
                  value={debitValues[field.id]}
                  onChange={handleDebitChange(field.id)}
                  onBlur={handleDebitOnBlur(field.id)}
                  id={field.id}
                  required
                />
                {debitError[field.id] && (
                  <FormHelperText
                    error
                    id={"error-text-" + field.id}
                    className={classes.error}
                  >
                    {debitError[field.id]}
                  </FormHelperText>
                )}
              </FormControl>
            ))}
          </form>
          <p>
            Ich ermächtige/ Wir ermächtigen (A) Abbott, Zahlungen von meinem/
            unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise
            ich mein/ weisen wir unser Kreditinstitut an, die von Abbott auf
            mein/ unser Konto gezogenen Lastschriften einzulösen. Hinweis: Ich
            kann/ Wir können innerhalb von acht Wochen, beginnend mit dem
            Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
            Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten
            Bedingungen.
          </p>
        </div>
      );
    }
    if (method === "creditcard") {
      return (
        <div>
          <form
            className={classes.form}
            autoComplete="off"
            style={{ marginBottom: 30 }}
          >
            {creditcardForm.map((field) => {
              if (field.type === "text" || field.type === "number") {
                return (
                  <FormControl key={field.id}>
                    <StyledInput
                      label={field.label}
                      variant="outlined"
                      size="small"
                      type={field.type}
                      value={creditcardValues[field.id]}
                      onChange={handleCreditcardChange(field.id)}
                      onBlur={handleCreditcardOnBlur(field.id)}
                      id={field.id}
                      required
                    />
                    {creditcardError[field.id] && (
                      <FormHelperText
                        error
                        id={"error-text-" + field.id}
                        className={classes.error}
                      >
                        {creditcardError[field.id]}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }
              if (field.type === "date") {
                return (
                  <FormControl key={field.id}>
                    <ThemeProvider theme={abbottTheme}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={deLocale}
                      >
                        <DatePicker
                          views={["year", "month"]}
                          orientation="portrait"
                          cancelLabel="Abbrechen"
                          inputVariant="outlined"
                          value={Date.parse(creditcardValues.date) || null}
                          disablePast
                          onChange={(date) => handleDateChange(date)}
                          onBlur={(event) => handleDateOnBlur(event)}
                          id={field.id}
                          required
                          format="MM.yyyy"
                          label={field.label}
                          DialogProps={{
                            disableScrollLock: true,
                          }}
                          InputProps={{
                            classes: {
                              root: classes.inputRoot,
                            },
                          }}
                          className={classes.input}
                        />
                      </MuiPickersUtilsProvider>
                      {creditcardError[field.id] && (
                        <FormHelperText
                          error
                          id={"error-text-" + field.id}
                          className={classes.error}
                        >
                          {creditcardError[field.id]}
                        </FormHelperText>
                      )}
                    </ThemeProvider>
                  </FormControl>
                );
              } else {
                return null;
              }
            })}
          </form>
          <p>
            Akzeptierte Kreditkarten: Visa, MasterCard, American Express, Diners
            Club, Discover und JCB.
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <h4>Zahlungsart wählen</h4>
      <FormControl style={{ width: "100%" }}>
        <RadioGroup
          aria-label="payment"
          name="Zahlungart"
          value={value}
          onChange={handleChange}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Grid container className={classes.container}>
                <Grid item lg={6} xs={6}>
                  <img
                    src={Lastschrift}
                    alt={Lastschrift}
                    style={{ marginRight: "40px" }}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <FormControlLabel
                    value="debit"
                    control={<BlueRadio />}
                    label="Lastschrift"
                    className={classes.customRadio}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container className={classes.container}>
                <Grid item lg={6} xs={6}>
                  <img
                    src={Kreditkarte}
                    alt={Kreditkarte}
                    style={{ marginRight: "40px" }}
                    height="47px"
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <FormControlLabel
                    value="creditcard"
                    control={<BlueRadio />}
                    label="Kreditkarte"
                    className={classes.customRadio}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>
        {error === true && (
          <FormHelperText error id={"error-text-payment-method"}>
            Bitte wählen Sie eine Zahlungsmethode aus.
          </FormHelperText>
        )}
      </FormControl>
      {value !== "" && (
        <div className={classes.paymentDataContainer}>
          <p>
            <strong>Bitte geben Sie ihre Zahlungsinformationen ein</strong>
          </p>

          {getPaymentForm(value)}
        </div>
      )}
    </div>
  );
};

export default Payment;
