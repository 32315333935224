// Externe Importe
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';
import HeaderImage from "../../img/startseite_header.jpg"

// Interne Importe
import { LIGHTGRAY } from "../../styles/colors";
import { getDateString, getProgramm } from "./dateHelper";

// Styles
const useStyles = makeStyles(() => ({
    printContainer: {
        padding: "2.5cm 2.5cm 2cm 2.5cm",
    },
    subHeading: {
        marginTop: 0,
        marginBottom: "1.5rem",
        letterSpacing: 0.6,
        fontWeight: 700,
    },
    greyContainer: {
        marginTop: 18,
        backgroundColor: LIGHTGRAY,
        padding: "1rem",
        lineHeight: "20px",
        width: "inherit",
    },
    tightText: {
        paddingBottom: 12,
    },
    greyCol: {
        display: "flex",
      },
}));

const EventPrint = ({ event }) => {
    // Variables
    const classes = useStyles();

    return (
        <div>
            <img src={HeaderImage} width="100%" alt="Bild auf die Bühne einer Veranstaltung" />
            <Container className={classes.printContainer}>
                <h1>{event.event_name}</h1>
                <h2 style={{ marginBottom: "2em" }}><b>VERANSTALTUNGS-KALENDER</b> &#47;&#47; {event.category_name}</h2>
                <h3 className={classes.subHeading}>Beschreibung</h3>
                <div style={{ marginBottom: "50px" }} id="wysiwyg">
                    {event.description ? ReactHtmlParser(event.description) : ""}
                    <p><b>Preis:</b> {event.costs}</p>
                </div>

                {/* Programm */}
                <div className={classes.greyContainer} style={{ pageBreakAfter: "always" }}>
                    <h3 className={classes.subHeading}>Programm</h3>
                    {event.items ? getProgramm(event.items) : null}
                </div>

                <Row style={{ marginTop: "2.5cm" }}>
                    <Col className={classes.greyCol}>
                        <div className={classes.greyContainer}>
                            <h3 className={classes.subHeading}>Wann und Wo</h3>
                            <Row>
                                <Col>
                                    Wann:{" "}
                                </Col>
                                <Col>
                                    {event.date_from &&
                                        getDateString(
                                            event.date_from,
                                            event.date_to,
                                            event.time_from,
                                            event.time_to
                                        )}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1.5rem" }}>
                                <Col>
                                    Veranstaltungsort:{" "}
                                </Col>
                                <Col>
                                    {event.addresses ? (
                                        <div>
                                            {event.addresses[0].address_name ? (
                                                <span>
                                                    {event.addresses[0].address_name}
                                                    <br />
                                                </span>
                                            ) : null}
                                            {event.addresses[0].street && (
                                                <span>
                                                    {event.addresses[0].street}
                                                    <br />
                                                </span>
                                            )}
                                            {event.addresses[0].post_code}{" "}
                                            {event.addresses[0].city}
                                        </div>
                                    ) : (
                                            ""
                                        )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className={classes.greyCol}>
                        <div className={classes.greyContainer} style={{ pageBreakAfter: "always" }}>
                            <h3 className={classes.subHeading}>Referenten</h3>
                            {event.speakers && event.speakers.length > 0 ? (
                                <div>
                                    {event.speakers.map((speaker) => (
                                        <div key={speaker.id} className={classes.tightText}>
                                            {speaker.speaker}
                                            <br />
                                            <i>{speaker.company_name}</i>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className={classes.greyCol}>
                        <div className={classes.greyContainer}>
                            <h3 className={classes.subHeading}>Veranstalter</h3>
                            {event.organizer ? (
                                <div>
                                    <div className={classes.tightText}>
                                        {event.organizer.organizer_name && (
                                            <span>
                                                {event.organizer.organizer_name}
                                                <br />
                                            </span>
                                        )}
                                        {event.organizer.contact_person_first_name ||
                                            event.organizer.contact_person_last_name ? (
                                                <span>
                                                    {event.organizer.contact_person_first_name}{" "}
                                                    {event.organizer.contact_person_last_name}
                                                    <br />
                                                </span>
                                            ) : null}

                                        {event.organizer.organizer_street && (
                                            <span>
                                                {event.organizer.organizer_street}
                                                <br />
                                            </span>
                                        )}
                                        {event.organizer.organizer_post_code ||
                                            event.organizer.organizer_city ? (
                                                <span>
                                                    {event.organizer.organizer_post_code}{" "}
                                                    {event.organizer.organizer_city}
                                                </span>
                                            ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Col>

                    <Col className={classes.greyCol}>
                        <div className={classes.greyContainer}>
                            <h3 className={classes.subHeading}>Sponsoren</h3>
                            {event.sponsors && event.sponsors.length > 0 ? (
                                <Row>
                                    {event.sponsors.map((sponsor) => (
                                        <Col lg={6} md={4} sm={6} xs={6}
                                            key={sponsor.id}
                                            style={{
                                                margin: "9px 0",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <a href={sponsor.website} style={{ alignSelf: "center" }}>
                                                <img
                                                    src={sponsor.baseImage}
                                                    alt={"Logo " + sponsor.name}
                                                    style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "70px",
                                                    }}
                                                />
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

EventPrint.propTypes = {
    event: PropTypes.object,
};


export default EventPrint;
