// Externe Importe
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

// Interne Importe
import http from "../../utils/http-common";
import { ContactDialog, EventBox } from "../index";

const RelatedEvents = ({ category_id }) => {
  // Variables
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");

  const requestEventInfos = (eventId) => {
    setContactDialogOpen(true);
    setSelectedEvent(eventId);
  };

  const handleContactClose = () => {
    setContactDialogOpen(false);
  };

  // Data fetching
  useEffect(() => {
    const fetchRelatedEvents = async () => {
      await http
        .get("/congresses/1/4", {
          params: { category_filter: category_id },
        })
        .then(function (response) {
          setRelatedEvents(response.data.events.data);
          //console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {});
    };
    fetchRelatedEvents();
  }, [category_id]);

  return (
    <div style={{ paddingBottom: "150px" }}>
      <div
        className="content-wrapper"
        style={{ borderTop: "2px solid #000000", paddingTop: "6.5rem" }}
      >
        <h3 className="headings">Ähnliche Termine</h3>
        <Grid container spacing={3}>
          {relatedEvents.map((event) => {
            return (
              <EventBox
                key={event.id}
                event={event}
                contactCallback={requestEventInfos}
              />
            );
          })}
        </Grid>
      </div>
      <ContactDialog
        isOpen={contactDialogOpen}
        handleClose={handleContactClose}
        eventId={selectedEvent}
      />
    </div>
  );
};

RelatedEvents.propTypes = {
  category_id: PropTypes.number.isRequired,
};

export default RelatedEvents;
