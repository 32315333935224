import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CorporateButton,
  EventBox,
  Portals,
  HeaderImage,
  ContactDialog,
} from "../../components";
import http from "../../utils/http-common";
import AddSharpIcon from "@material-ui/icons/AddSharp";

import Background from "../../img/startseite_header.jpg";

const useStyles = makeStyles((theme) => ({
  headerImg: {
    backgroundImage: Background,
  },
  headerText: {
    boxShadow: "none",
    borderRadius: 0,
  },
  categorySection: {
    paddingBottom: "6.5rem",
  },
  category: {
    position: "relative",
    height: "100%",
    padding: "30px",
    boxShadow: "none",
    borderRadius: 0,
    textAlign: "center",
    color: "#fff",
    "& p": {
      marginBottom: "55px",
    },
  },
  categoryTitle: {
    fontWeight: 700,
    marginBottom: "17.5px",
  },
  buttonLight: {
    position: "absolute",
    bottom: "30px",
    right: "calc(50% - 49.5px)",
    backgroundColor: "#fff",
    color: "#000",
    "&::before": {
      borderColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  bottom: {
    paddingBottom: "150px",
  },
  paper: {
    boxShadow: "none",
    borderRadius: 0,
    cursor: "pointer",
    backgroundColor: "#f2f2f2",
    transition: "background-color .25s",
    height: "100%",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#e5f4fb",
    },
  },
  icon: {
    fontSize: "4.8rem",
  },
}));

const Start = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [amount, setAmount] = useState(7);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");

  const loadMore = () => {
    setAmount(amount + 8);
  };

  const handleContactClose = () => {
    setContactDialogOpen(false);
  };

  const requestEventInfos = (eventId) => {
    setContactDialogOpen(true);
    setSelectedEvent(eventId);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then(function (response) {
          setCategories(response.data.categories);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      await http
        .get("/congresses/1/" + amount)
        .then(function (response) {
          setEvents(response.data.events.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchEvents();
  }, [amount]);

  return (
    <div className="site-wrapper">
      <HeaderImage withCategory={false} />
      <div id="start">
        <div className="content-wrapper">
          <h1 className="re">
            Nur ein Herzschlag bis <br />
            zur nächsten Veranstaltung
          </h1>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.headerText}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.headerText}>
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Duis autem
                vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat, vel illum dolore eu feugiat nulla facilisis
                at vero eros et accumsan et iusto odio dignissim qui blandit
                praesent luptatum zzril delenit augue duis dolore te feugait
                nulla facilisi. Lorem ipsum dolor sit amet, consectetuer
                adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                laoreet dolore magna aliquam erat volutpat.
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <div id="kategorie" className={classes.categorySection}>
        <div className="content-wrapper">
          <h3 className="headings">Wählen sie eine Kategorie</h3>
          <Grid container spacing={3}>
            {categories.map((category) => {
              const bg = {
                backgroundColor: category.color,
              };

              return (
                <Grid key={category.id} item xs={12} lg={4} md={6}>
                  <Paper className={classes.category} style={bg}>
                    <h3 className={classes.categoryTitle}>
                      {category.category_name}
                    </h3>
                    <p>{category.description}</p>
                    <CorporateButton
                      className={classes.buttonLight}
                      href={"/events/" + category.id}
                    >
                      weiter
                    </CorporateButton>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <Portals />
      <div id="termine" className={classes.bottom}>
        <div className="content-wrapper">
          <h3 className="headings">Die nächsten Termine</h3>
          <Grid container spacing={3}>
            {events.map((event) => {
              return <EventBox key={event.id} event={event} contactCallback={requestEventInfos}/>;
            })}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Paper className={classes.paper} onClick={loadMore}>
                <AddSharpIcon className={classes.icon} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <ContactDialog
        isOpen={contactDialogOpen}
        handleClose={handleContactClose}
        eventId={selectedEvent}
      />
    </div>
  );
};

export default Start;
