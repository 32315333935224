import React from "react";

import {HeaderImage} from "../../components";

const Error401 = () => {
  return (
    <div className="site-wrapper">
      <HeaderImage withCategory={false} />
      <div id="start">
        <div className="content-wrapper">
            <h1><strong>Fehler 401</strong> - Nicht autorisiert</h1>
            <p>Sie haben keinen Zugriff auf diese Seite.</p>
        </div>
      </div>
    </div>
  );
};

export default Error401;
