import axios from "axios";

const http = axios.create({
    /* Wenn vor Ort: */
    /* baseURL: "https://marge6-php74.grips.local/abbott-veranstaltungskalender-v2/web_laravel/public/api/", */
    /* Wenn via VPN: */
    /* baseURL: "https://192.168.0.234:32769/abbott-veranstaltungskalender-v2/web_laravel/public/api/", */
    /* Online: */
    baseURL: "https://api.veranstaltungs-kalender.de/api",
      /* Devilbox: */
    // baseURL: "http://abbott-veranstaltungskalender-v2.loc/public/api/",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
    }
});

export default http;