// Externe Importe
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";

//Interne Importe
import { WHITE } from "../../styles/colors";
import pencil from "../../img/icon_anmelden_big.png";

// Styles
const useStyles = makeStyles(() => ({
  box: {
    width: "inherit",
    padding: "1rem",
    marginTop: "18px",
    lineHeight: "20px",
    color: WHITE,
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 2.8px 7px rgba(0, 0, 0, 0.22)",
    },
    borderRadius: "0 0 50px 0",
  },
  specialText: {
    letterSpacing: 0.6,
    fontFamily: "brandon-grotesque,sans-serif",
    fontWeight: 700,
  },
}));

const EnrollCol = ({ color, price, params }) => {
  // Variables
  const classes = useStyles();
  const boxStyle = {
    backgroundColor: `${color}`,
  };
  const small = useMediaQuery('(min-width:380px)');
  const history = useHistory();

  const navigateToEnroll = () => {
    history.push(`/anmelden/${params.event_id}/${params.doctor_id}/${params.hash}`);
  }

  return (
    <Col xs={12}>
      <div style={boxStyle} className={classes.box} onClick={() => navigateToEnroll()}>
        <p style={{ marginBottom: "32px" }}>
          Füllen Sie das folgende Formular vollständig aus, um sich für diese
          Veranstaltung anzumelden:
        </p>
        <Row>
          <Col xs={small ? 3 : 12} style={{ display: "flex", alignItems: "flex-end" }}>
            <span className={classes.specialText}>{price ? (Math.round(parseFloat(price) * 100)/100).toFixed(2) + "€" : ""}</span>
          </Col>
          <Col
            style={small ? {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            } : {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
          >
            <span
              style={{ marginRight: "16px" }}
              className={classes.specialText}
            >
              JETZT ANMELDEN
            </span>
            <img style={small ? { marginRight: "16px" } : { marginRight: "16px", marginTop: "6px" }} src={pencil} alt="Stift" />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

EnrollCol.propTypes = {
  color: PropTypes.string,
  price: PropTypes.string,
  params: PropTypes.object,
};

export default EnrollCol;
