import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Layout  from  "../../img/layout.svg";
import Logo from "../../img/abbott-logo.png";
import Logo2 from "../../img/logo_abbott_eductaion.jpg"
import "./Footer.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { PRIMARY_LIGHT, TITLEFONT } from "../../styles/colors";
import { getYear } from 'date-fns';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#000',
        color: '#fff',
    },
    footerCol: {
        backgroundColor: "#000",
        boxShadow: "none",
        borderRadius: 0,
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "0.85px",
        fontFamily: TITLEFONT,
        textTransform: "uppercase",
        color: "#fff",
        paddingTop: "20px",
        '& p': {
            marginTop: "4px",
            fontWeight: 700,
        }
    },
    footerColRight: {
        backgroundColor: "#000",
        textAlign: "right"
    },
    ul: {
        marginTop: "30px",
        paddingLeft: 0,
        listStyle: "none",

        '& li' : {
            padding: "8px 0",
        }
    },
    copyright: {
        fontSize: "12px",
        paddingTop: "50px!important",
        paddingBottom: "40px!important",
        letterSpacing: "0.6px"
    }
}));


const Footer = (props) => {
    const classes = useStyles();
    const year = getYear(new Date());
    let path = useLocation().pathname;

    return (
        <div id="footer" className={classes.footer}>
            <img className="layout-helper-right" src={Layout} alt="Layout Helper" width="50px" height="70px"/>
            <div className="content-wrapper">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Paper className={classes.footerCol}>
                            <img src={Logo} alt="Logo Abbott" width="103px" height="26px"/>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Paper className={classes.footerCol}>
                            <p>Veranstaltungen</p>
                            <ul className={classes.ul}>
                                {props.categories.map((category) => {
                                    return (
                                        <li key={category.id}><a href={'/events/'+category.id}>{category.category_name}</a></li>
                                    )
                                })}
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item lg={3}  md={4} sm={6} xs={12}>
                        <Paper className={classes.footerCol}>
                            <p>Sonstiges</p>
                            <ul className={classes.ul}>
                                <li><a href={'/ueber-uns'} style={path.startsWith("/ueber-uns") ? {color: PRIMARY_LIGHT } : null}>Über uns</a></li>
                                <li><a href={'/kontakt'} style={path.startsWith("/kontakt") ? {color: PRIMARY_LIGHT } : null}>Kontakt</a></li>
                                <li><a href={'/impressum'} style={path.startsWith("/impressum") ? {color: PRIMARY_LIGHT } : null}>Impressum</a></li>
                                <li><a href={'/datenschutz'} style={path.startsWith("/datenschutz") ? {color: PRIMARY_LIGHT } : null}>Datenschutz</a></li>
                                <li><a className="privacy_settings" id="privacy_settings" href="#privacy_settings">Datenschutzeinstellungen</a></li>
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item lg={3}  md={4} sm={12} xs={12}>
                        <Paper className={classes.footerCol} /* className={classes.footerColRight} */>
                            <img src={Logo2} alt="Logo Abbott Education" width="216px" height="144px"/>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.copyright}>
                        <p>© {year} Abbott. Alle Rechte vorbehalten. Weitere Einzelheiten entnehmen Sie bitte dem Impressum.</p>

                        Sofern nichts anderes angegeben ist, handelt es sich bei dem Namen aller Produkte und Dienstleistungen auf dieser Website um Warenmarken im Besitz oder unter Lizenz des Unternehmens Abbott, seiner Tochtergesellschaften oder der mit ihm verbundenen Unternehmen. Die Warenmarken, Markennamen oder Handelsaufmachungen von Abbott auf dieser Website dürfen ohne vorherige schriftliche Genehmigung von Abbott nur zur Kennzeichnung der entsprechenden Produkte oder Dienstleistungen des Unternehmens verwendet werden.
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Footer;